import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './airqualityindex.css';



class AirQualityIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: null
        };
    }

    componentWillMount() {
        if(this.props.value >= 0 && this.props.value < 10){
            this.setState({
                index: 'air-quality-index-1'
            })
        }else if(this.props.value >= 10 && this.props.value < 20){
            this.setState({
                index: 'air-quality-index-2'
            })
        }else if(this.props.value >= 20 && this.props.value < 25){
            this.setState({
                index: 'air-quality-index-3'
            })
        }else if(this.props.value >= 25 && this.props.value < 50){
            this.setState({
                index: 'air-quality-index-4'
            })
        }else if(this.props.value >= 50){
            this.setState({
                index: 'air-quality-index-5'
            })
        }
    }

    render() {
        const { title, timestamp, value, unit, fixed, ...other } = this.props;

        return (
            <>
                <div className={'air-quality-index-div ' + this.state.index}>
                    <div className="air-quality-index-div-title">{title}</div>
                    <div className="air-quality-index-div-timestamp">{moment(timestamp).format('DD/MM/YYYY HH:mm')}</div>
                    <div className="air-quality-index-div-value">{value.toFixed(fixed)}<span style={{fontSize: "0.5em"}}> {unit}</span></div>
                </div><br/>
            </>

        );
    }
}

AirQualityIndex.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
    fixed:  PropTypes.number.isRequired
};

export default AirQualityIndex;
