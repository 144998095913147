import React from "react";
import * as crossfilter from "crossfilter2";
import {csv, utcParse, timeFormat, timeParse, timeMonth, timeDay, format} from 'd3'
import "dc/dc.css";
import moment from 'moment';

import pollutants from '../tmp/pollutants.csv';

export const CXContext = React.createContext("CXContext");
export const dateFormatSpecifier = '%Y-%m-%dT%H:%M:%SZ';
export const dateFormat = timeFormat(dateFormatSpecifier);
export const dateFormatParser = utcParse(dateFormatSpecifier);
export const numberFormat = format('.2f');

export class DataContext extends React.Component {
  constructor(props) {
    super(props);
    this.state={loading:false,hasNDX:false};
  }

  componentDidMount(){
      if (this.state.hasNDX){
          return
      }
      if(this.state.loading){
          return
      }
      this.setState({loading:true})
      csv(pollutants)
        .then((data)=> {
            data.forEach(function (d) {
                d.dd = dateFormatParser(d.DateTime);
                d.humanDate = moment(d.DateTime).format("YYYY-MM-DD");
                d.month = timeMonth(d.dd); // pre-calculate month for better performance
                d.day = d.dd.getDay();
                d.hour = d.dd.getHours();
                d.PM1 = +d.PM1; // coerce to number
                d.PM25 = +d.PM25;
                d.PM10 = +d.PM10;
            });

            data.sort(function(a,b){return a.humanDate-b.humanDate});

            this.ndx = crossfilter(data); //TODO possibly need to update this
            this.setState({loading:false,hasNDX:true})
        })
  }

  render() {
      if(!this.state.hasNDX){
          return null;
      }
    return (
      <CXContext.Provider value={{ndx:this.ndx}}>
        <div ref={this.parent}>
        {this.props.children}
        </div>
      </CXContext.Provider>
    );
  }
}
