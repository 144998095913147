import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'map',
		url: StaticRoutes.PPcityMap,
		icon: 'icon-map',
		role: roles.ADMIN
	},
];

export default menu;
