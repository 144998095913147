import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Card,
	CardBody,
	Table,
} from 'reactstrap';
import { FormattedDate } from 'react-intl';

import { Map as LeafletMap, Marker, Popup, TileLayer, GeoJSON, WMSTileLayer, ImageOverlay } from 'react-leaflet';
import Control from 'react-leaflet-control';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';
import proj4 from 'proj4';
import sat from '../../img/sat.png';

import { Api } from 'core/api';
import { DynamicRoutes } from 'flows-app/model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import { requestData } from 'core/ducks/list';
import { getData } from 'core/ducks/update';
import T from 'modules/i18n';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
	iconSize: [12, 20],
	iconAnchor: [6, 20],
	popupAnchor: [1, -34],
	shadowSize: [20, 20]
});

const blueIcon = new L.Icon({
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: 'leaflet/dist/images/marker-shadow.png',
	iconSize: [12, 20],
	iconAnchor: [6, 20],
	popupAnchor: [1, -34],
	shadowSize: [20, 20]
});

const orangeIcon = new L.Icon({
	iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
	shadowUrl: 'leaflet/dist/images/marker-shadow.png',
	iconSize: [12, 20],
	iconAnchor: [6, 20],
	popupAnchor: [1, -34],
	shadowSize: [20, 20]
});

class WorkflowsMap extends Component {

	constructor(props) {
		super(props);
		proj4.defs('EPSG:2100', "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs");

		this.state = {
			boundingBox: [],
			points: {},
			markerClicked: false,
			selected: '',
			pending: false,
			proc: '',
			polygon: {},
			geotif: '',
			showAerial: false,
			topoBbox: []
		};

		this.mapRef = React.createRef();

		this.handleMapChange = this.handleMapChange.bind(this);
		this.createUrl = this.createUrl.bind(this);
		this.requestData = this.requestData.bind(this);
		this.handleMarkerClick = this.handleMarkerClick.bind(this);
	}

	handleMapChange(event) {
		const bounds = event.target.getBounds();
		let northEast = bounds._northEast;
		northEast = proj4('EPSG:4326', 'EPSG:2100', [northEast.lng, northEast.lat]);
		let southWest = bounds._southWest;
		southWest = proj4('EPSG:4326', 'EPSG:2100', [southWest.lng, southWest.lat]);
		this.setState({
			boundingBox: [...southWest, ...northEast, 2100]
		}, this.requestData);
	}

	handleMarkerClick(event) {
		const target = event.target;
		const uuid = target.options.value;
		this.setState({markerClicked: true, selected: uuid});
		const url = `application/uuid/${uuid}`;
		this.props.dispatch( getData(url) );
		this.featureRequest(uuid, 'polygon');
		// this.props.dispatch( requestData('extra', `locations/uuid/${uuid}`) );
	}

	featureRequest(uuid, feature) {
		this.setState({[feature]: {}});
		let a = new Api(`locations/feature/${feature}/uuid/${uuid}`);
		a.Get().then(response => {
			return response.json();
		}).then(json => {
			if (json)
				this.setState({[feature]: json});
		});
	}

	createUrl() {
		const boundingBox = this.state.boundingBox.join(',');
		let url = `locations/geom/${boundingBox}`;
		let { query, category, status } = this.props.queries;
		query = (query && query!=='') ? '/query/' + query : query;
		let fq = [];
		if (status && status!=='')
			fq.push('status:' + status);
		if (category && category!=='')
			fq.push('workflow:' + category);
		fq = fq.join(';');
		fq = (fq !== '') ? '/fq/' + fq : fq;
		url += query + fq;

		return url;
	}

	requestData() {
		const { dispatch } = this.props;
		if (this.state.pending)
			clearTimeout(this.state.proc);
		let proc = setTimeout(() => {
			const url = this.createUrl();
			dispatch( requestData('locations', url) ).then(() => {
				this.setState({
					points: this.props.list.locations.data
				});
			});
			this.setState({pending: false, proc: ''});
		}, 100);
		this.setState({
			pending: true,
			proc
		});
	}

	showTopo(uuid) {
		let a = new Api(`locations/feature/bbox/uuid/${uuid}`);
		a.Get().then((response) => {
			return response.json();
		}).then((json) => {
			this.setState({topoBbox: json});
		});
	}

	componentDidMount() {
		this.handleMapChange({target: this.mapRef.current.leafletElement});
	}

	componentDidUpdate(prevProps) {
		const { query, status, category } = this.props.queries
		if (query !== prevProps.queries.query || status !== prevProps.queries.status || category !== prevProps.queries.category)
			this.requestData();
	}

	render() {
		const { points } = this.state;
		let { info, data_pending } = this.props;
		return (
			<Row>
				<Col>
					<Card className="m-0">
						<CardBody style={{padding: 5 + 'px'}}>
							<LeafletMap
								style={{width: 100 + '%', height: 70 + 'vh'}}
								center={[40.634996, 22.936076]}
								zoom={9}
								maxZoom={20}
								onzoomend={this.handleMapChange}
								onmoveend={this.handleMapChange}
								ref={this.mapRef}
							>
								<TileLayer
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
								/>
								{ this.state.showAerial &&
									<WMSTileLayer
										layers="KTBASEMAP"
										url="http://gis.ktimanet.gr/wms/wmsopen/wmsserver.aspx?"
										opacity={0.4}
									/>
								}
								{ Object.keys(this.state.polygon).length !== 0 &&
									<GeoJSON
										key={this.state.selected}
										data={this.state.polygon}
									/>
								}
								{ (this.state.topoBbox.length === 4) &&
									<ImageOverlay
										url={`/api/locations/feature/geotif/uuid/${this.state.selected}.png`}
										bounds={this.state.topoBbox}
										opacity={0.8}
										zIndex={100000}
									/>
								}
								<Control position="topright">
									<button
										style={{
											backgroundColor: 'rgba(0,0,0,0)',
											borderStyle: this.state.showAerial ? 'inset' : 'outset'
										}}
										onClick={() => {this.setState({showAerial: !this.state.showAerial})}}
									>
										<img src={sat} alt="Aerial" />
									</button>
								</Control>
								<MarkerClusterGroup>
									{ Object.keys(points).map((index) => {
										let markerPosition = [points[index].lat, points[index].lon];
										return (
											<Marker
												key={index}
												position={markerPosition}
												style={{width: 5 + 'px', height: 5 + 'px'}}
												onClick={this.handleMarkerClick}
												value={index}
												icon={(this.state.selected === index) ? orangeIcon : blueIcon}
											>
												<Popup autoPan={false}>
													{(!data_pending && this.state.markerClicked) && (
														<Table className="mb-1">
															<tbody>
																<tr>
																	<th className="small p-0 pr-1"><T>registry_number</T></th>
																	<td className="small p-0">{info.registry_number}</td>
																</tr>
																<tr>
																	<th className="small p-0 pr-1"><T>author</T></th>
																	<td className="small p-0">{info.author}</td>
																</tr>
																<tr>
																	<th className="small p-0 pr-1"><T>created</T></th>
																	<td className="small p-0">
																		{ info.created &&
																			<FormattedDate
																				value={info.created}
																				year='numeric'
																				month='long'
																				day='2-digit'
																			/>
																		}
																	</td>
																</tr>
																<tr>
																	<th className="small p-0 pr-1"><T>revision</T></th>
																	<td className="small p-0">{info.revision}</td>
																</tr>
																<tr>
																	<th className="small p-0 pr-1"><T>step</T></th>
																	<td className="small p-0">{info.step}</td>
																</tr>
																<tr>
																	<th className="small p-0 pr-1"><T>reviewer</T></th>
																	<td className="small p-0">{info.reviewer}</td>
																</tr>
																<tr>
																	<th className="small p-0 pr-1"><T>status</T></th>
																	<td className="small p-0"><T>{info.status}</T></td>
																</tr>
															</tbody>
														</Table>
													)}
													<Row>
														<Col className="pr-0">
															{/*eslint-disable-next-line*/}
															<a href="#" onClick={() => this.showTopo(index)}>
																Ενσωμάτωση<br/>τοπογραφικού
															</a>
														</Col>
														<Col className="px-0">
															<a target="_blank" rel="noopener noreferrer" href={buildPath(DynamicRoutes.Apply, [index, 'current'])}><T>see in detail</T></a>
														</Col>
													</Row>
												</Popup>
											</Marker>
										);
									}) }
								</MarkerClusterGroup>
							</LeafletMap>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}

}

const mapStateToProps = (state) => ({
	list: state.list,
	info: state.update.response,
	data_pending: state.update.pending,
	queries: state.ui.menu.asideState,
});

WorkflowsMap = connect(mapStateToProps)(WorkflowsMap);

export default WorkflowsMap;
