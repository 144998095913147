import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Input, InputGroup, InputGroupAddon, InputGroupText, FormGroup, Form } from 'reactstrap';

import { setQuery } from '../ducks/search';
import { StaticRoutes } from '../model/routes';

class Search extends Component {

	constructor(props) {
		super(props);
		this.state = {
			search: ''
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.resetSearch = this.resetSearch.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInputChange(event) {
		let target = event.target;
		this.setState({
			[target.name]: target.value
		});
	}

	resetSearch() {
		this.setState({
			search: ''
		});
	}

	handleSubmit(event=null) {
		if (event)
			event.preventDefault();
		this.props.dispatch( setQuery(this.state.search) );
		this.props.history.push(StaticRoutes.AdminSearch);
		this.resetSearch();
	}

	render() {
		const { search } = this.state;
		return (
			<Form onSubmit={this.handleSubmit}>
				<FormGroup className="mx-2 mt-5 mb-2 on-maximized">
					<InputGroup>
						<Input
							className="rounded-left border-right-0 focus-no"
							type="search"
							placeholder={this.props.i18n.search + '...'}
							name="search"
							value={search}
							onChange={this.handleInputChange}
							autoComplete="off"
						/>
						<InputGroupAddon addonType="append">
							{ search !== '' &&
								<InputGroupText className="bg-white text-secondary border-right-0 px-1">
									<i className="fa fa-times" onClick={this.resetSearch}/>
								</InputGroupText>
							}
							<InputGroupText
								className="bg-light rounded-right border-left-0 px-2"
								role="button"
								onClick={this.handleSubmit}
							>
								<i className="fa fa-search"/>
							</InputGroupText>
						</InputGroupAddon>
					</InputGroup>
				</FormGroup>
				<i className="fa fa-search on-minimized mt-5" onClick={this.handleSubmit} title={this.props.i18n.search}/>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n.messages
});

Search = connect(mapStateToProps)(Search);

export default withRouter(Search);