import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, Label, Button, FormFeedback } from 'reactstrap';

import { Input } from 'core/components';
import T from 'modules/i18n';
import { getValidation, validate } from 'core/ducks/forms';
import { Loading } from 'core/components';

class CreateTypology extends Component {

	constructor(props) {
		super(props);
		const initialValues = {
			label: '',
			description: ''
		};
		this.state = {
			values: {...initialValues},
			underSubmit: false
		};
		this.initialValues = {...initialValues};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.resetForm = this.resetForm.bind(this);
	}

	handleChange(event) {
		let target = event.target;
		this.setState({
			values: {
				...this.state.values,
				[target.name]: target.value
			},
			underSubmit: false
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		this.setState({underSubmit: true}, () => dispatch(validate(this.state.values, rules)));
	}

	resetForm() {
		this.setState({
			values: {...this.initialValues}
		});
	}

	componentDidMount() {
		const { dispatch, rules } = this.props;
		dispatch(getValidation('project'));
	}

	componentDidUpdate(prevProps) {
		if ( this.state.underSubmit && this.props.valid) {
			const { values } = this.state;
			console.log(values);
			this.setState({underSubmit: false});
		}
	}

	render() {
		const { rules, validation_msgs } = this.props;

		if (Object.keys(this.props.rules).length === 0)
			return (<Loading />);

		return (
			<Form onSubmit={this.handleSubmit}>
				<Row>
					<Col className="text-muted">
						[Placeholder] Εδώ θα μπει κείμενο περιγραφής της παρακάτω φόρμας.
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor="label_input"><T>label</T>*</Label>
							<Input
								id="label_input"
								type="text"
								name="label"
								value={this.state.values.label}
								required={true}
								autoComplete="off"
								onChange={this.handleChange}
								pattern={rules.label.validation}
								maxLength={rules.label.max_size || undefined}
							/>
							<FormFeedback>{validation_msgs.label}</FormFeedback>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor="description_input"><T>description</T></Label>
							<Input
								id="description_input"
								type="textarea"
								name="description"
								value={this.state.values.description}
								autoComplete="off"
								onChange={this.handleChange}
								pattern={rules.description.validation}
							/>
							<FormFeedback>{validation_msgs.description}</FormFeedback>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col className="text-right">
						<Button color="primary" type="submit" className="mx-1 my-3">
							<T>next</T>
						</Button>
						<Button color="secondary" type="button" className="mx-1 my-3" onClick={this.resetForm}>
							<T>reset</T>
						</Button>
					</Col>
				</Row>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	rules: state.forms.validation.rules,
	valid: state.forms.valid,
	validation_msgs: state.forms.validation_msgs,
});

CreateTypology = connect(mapStateToProps)(CreateTypology);

export default CreateTypology;
