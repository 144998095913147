import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    CardGroup,
    Card,
    CardBody,
    Button
} from 'reactstrap';
import T from 'modules/i18n';
import {StaticRoutes} from "../../model/routes";

import 'ol/ol.css'
import 'ol-ext/dist/ol-ext.css'
import Map from 'ol/Map';
import View from 'ol/View';
import {defaults as defaultControls} from 'ol/control';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import PointGeom from 'ol/geom/Point';
import {fromLonLat} from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import {Circle as CircleStyle, Fill, Text, Style} from 'ol/style';
import OSM from 'ol/source/OSM';
import OverlayControl from 'ol-ext/control/Overlay';
import SelectInteraction from 'ol/interaction/Select';

import LayerPopup from "ol-ext/control/LayerPopup";


import AirqualitylegendInfoControl from '../../components/airqualitylegend/airqualitylegend';
import airqualityindeximg from 'core/views/layout/img/airqualityindex.jpg';

import moment from 'moment';
import Dygraph from 'dygraphs';
import {requestData} from "core/ducks/list";
import Temp from "./tmp/temperatures.csv";
import './css/thermiair.css';


class PollutionMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            data: null,
            aq1Layer: null,
            pm1Layer: null,
            pm25Layer: null,
            pm10Layer: null
        };

        // this.refreshingData();
    }

    componentDidMount() {
        let t = this;
        let LegendControl = new AirqualitylegendInfoControl();

        let map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM()
                })
            ],
            controls: defaultControls({attribution: false}).extend([
                new LayerPopup(),
                LegendControl
            ]),
            target: 'pollution-map',
            view: new View({
                center: [2568716.458, 4937330.641],
                zoom: 12,
                minZoom: 10,
                extent: [2523768.436, 4922345.743, 2595466.368, 4955137.228]
            })
        });
        LegendControl.set(map);

        // Popup overlay
        let popup = new OverlayControl({hideOnClick: false, className: ''});
        map.addControl(popup);

        // Set wipe parameters before/after visibility change
        popup.on("change:visible", function (e) {	// wipe needs to know the size of the element to clip on
            if (/wipe/.test('')) {
                if (e.visible) {
                    document.getElementsByClassName('ol-overlay')[0].style.clip = "rect(0px," + map.getSize()[0] + "px," + map.getSize()[1] + "px,0px)";
                } else {
                    document.getElementsByClassName('ol-overlay')[0].style.clip = "rect(0px,0px," + map.getSize()[1] + "px,0px)";
                }
            }
            // Reset clip for others
            else document.getElementsByClassName('ol-overlay')[0].style.clip = '';
        });

        // Control Select
        let select = new SelectInteraction({});
        map.addInteraction(select);
        // Remove selection on close
        popup.on("change:visible", function (e) {
            if (!e.visible) select.getFeatures().clear();
        });
        // On selected => show/hide popup
        select.getFeatures().on('add', function (e) {
            let feature = e.element;
            let c = t.setColor(feature.get('value'));

            t.props.dispatch(requestData('stationStatisticsData', 'data/token/' + feature.get('token'))).then(() => {
                let d = t.props.stationStatisticsDataList;

                t.props.dispatch(requestData('stationWeekData', 'data/token/' + feature.get('token') + '/timeseries/1%20week')).then(() => {

                    let series = [];

                    let s = t.props.stationWeekDataList.series;
                    s.map(function (item) {
                        series.push([new Date(item.result_time), parseFloat(item.value)])
                    });

                    series.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return a[0] - b[0];
                    });

                    let content = `
                    <div id="currentconditions" class="current-conditions" style="background-color: ${c[0]};color: ${c[1]};height: 460px;">
                        <span id="ol-stationInfoDiv-close">x</span>
                        <h4> ${t.props.i18n['thermiairSensor']}: ${feature.get('name')}</h4>                        
                        <div class="popup-time-stamp">${feature.get('result_time')}</div>
                        <div class="popup-conditions">${t.props.i18n['thermiairRealTime']} ${feature.get('parameter')} ${t.props.i18n['thermiairIsNow']}</div>
                        <div class="popup-aqi popup-large">${feature.get('value')}<span style="font-size: 0.5em;"> ${feature.get('unit')}</span></div>
                        <div class="popup-history">
                            <span class="legend-tooltip-popup" style="background:${t.setColor(d['10 minutes'])[0]};color:${t.setColor(d['10 minutes'])[1]};">
                                <div>10 ${t.props.i18n['thermiairMin']}</div>${d['10 minutes'].toFixed(0)}                              
                            </span>
                            <span class="legend-tooltip-popup" style="background:${t.setColor(d['30 minutes'])[0]};color:${t.setColor(d['30 minutes'])[1]};">
                                <div>30 ${t.props.i18n['thermiairMin']}</div>${d['30 minutes'].toFixed(0)}                                        
                            </span>
                            <span class="legend-tooltip-popup" style="background:${t.setColor(d['1 hour'])[0]};color:${t.setColor(d['1 hour'])[1]};">
                                <div>1 ${t.props.i18n['thermiairHour']}</div>${d['1 hour'].toFixed(0)}                       
                            </span>
                            <span class="legend-tooltip-popup" style="background:${t.setColor(d['6 hours'])[0]};color:${t.setColor(d['6 hours'])[1]};">
                                <div>6 ${t.props.i18n['thermiairHours']}</div>${d['6 hours'].toFixed(0)}                           
                            </span>
                            <span class="legend-tooltip-popup" style="background:${t.setColor(d['24 hours'])[0]};color:${t.setColor(d['1 week'])[1]};">
                                <div>1 ${t.props.i18n['thermiairDay']}</div>${d['24 hours'].toFixed(0)}  
                            </span>
                            <span class="legend-tooltip-popup" style="background:${t.setColor(d['1 week'])[0]};color:${t.setColor(d['1 week'])[1]};">
                                <div>${t.props.i18n['thermiairWeek']}</div>${d['1 week'].toFixed(0)}
                            </span>
                        </div>
                        <div id="chart-week-container" >
                            <div id="chart-week" />
                        </div>
                        
                        <!--<div class="popup-more">${t.props.i18n['thermiairMore']}</div>-->
                    </div>`;

                    popup.show(content, /frompoint/.test('') ? feature.getGeometry().getCoordinates() : null);

                    document.getElementById('ol-stationInfoDiv-close').onclick = function () {
                        popup.hide();
                    };

                    new Dygraph('chart-week',
                        series, {
                            labels: ['Date', t.props.i18n['thermiairValue']],
                            title: t.props.i18n['thermiairVariation'],
                            fillGraph: true,
                            showRangeSelector: true
                        });
                });
            });
        });


        // let aq1Layer = this.createLayer('AQI', true, 'aq1', airqualityindeximg);
        // let pm1Layer = this.createLayer('PM1.0', false, 'pm1', airqualityindeximg);
        // let pm25Layer = this.createLayer('PM2.5', false, 'pm25', airqualityindeximg);
        // let pm10Layer = this.createLayer('PM10', false, 'pm10', airqualityindeximg);
        //
        // map.addLayer(aq1Layer);
        // map.addLayer(pm1Layer);
        // map.addLayer(pm25Layer);
        // map.addLayer(pm10Layer);

        // this.setState({
        //     map: map,
        // aq1Layer: aq1Layer,
        // pm1Layer: pm1Layer,
        // pm25Layer: pm25Layer,
        // pm10Layer: pm10Layer
        // });

        this.setData(map)
    }

    setColor(value) {
        let s = '';
        let f = 'black';
        if (value >= 0 && value < 10) {
            s = '#5ed5ff'
        } else if (value >= 10 && value < 20) {
            s = '#92d14f'
        } else if (value >= 20 && value < 25) {
            s = '#ffff00'
        } else if (value >= 25 && value < 50) {
            s = '#fc3903';
            f = 'white'
        } else if (value >= 50) {
            s = '#990100';
            f = 'white'
        }
        return [s, f];
    }

    createLayer(id, visible, value, type, legend) {
        // console.log(value);
        let features = [];
        let stations = value;
        stations.forEach(function (station) {
                let idx = null;

                station.datastreams.map((obj, index) => {
                    if (obj.label === type) {
                        idx = index
                    }
                });

                let feature = new Feature({
                    geometry: new PointGeom(fromLonLat([Number(station.lon), Number(station.lat)])),
                    name: station.description,
                    token: station.datastreams[idx].token,
                    result_time: station.datastreams[idx].result_time,
                    value: station.datastreams[idx].value.toFixed(0),
                    unit: station.datastreams[idx].unit.symbol,
                    parameter: id
                });
                features.push(feature);
            }
        );
        let vectorSource = new VectorSource({features: features});

        let fillClass1 = new Fill({color: [94, 213, 255, 1]});
        let fillClass2 = new Fill({color: [146, 209, 79, 1]});
        let fillClass3 = new Fill({color: [255, 255, 0, 1]});
        let fillClass4 = new Fill({color: [252, 57, 3, 1]});
        let fillClass5 = new Fill({color: [153, 1, 0, 1]});

        let setStyle = function (feature) {
            let s = new Fill({color: [0, 0, 0, 0.5]});
            let f = new Fill({color: '#000'});
            if (feature.get('value') >= 0 && feature.get('value') < 10) {
                s = fillClass1
            } else if (feature.get('value') >= 10 && feature.get('value') < 20) {
                s = fillClass2
            } else if (feature.get('value') >= 20 && feature.get('value') < 25) {
                s = fillClass3
            } else if (feature.get('value') >= 25 && feature.get('value') < 50) {
                s = fillClass4;
                f = new Fill({color: '#fff'})
            } else if (feature.get('value') >= 50) {
                s = fillClass5;
                f = new Fill({color: '#fff'})
            }
            return new Style({
                image: new CircleStyle({
                    fill: s,
                    radius: 12
                }),
                text: new Text({
                    textAlign: 'center',
                    textBaseline: 'middle',
                    font: '14px Calibri,sans-serif',
                    text: feature.get('value').toString(),
                    fill: f,
                    placement: 'point',
                    overflow: 'true'
                })
            });
        };
        return new VectorLayer({
            id: id,
            title: id,
            source: vectorSource,
            style: setStyle,
            baseLayer: true,
            visible: visible,
            legend: legend
        });
    }

    setData(map) {
        this.setState({refreshing: true});
        this.props.dispatch(requestData('dashboardData', 'data/bbox/180,180,-180,-180,4326')).then(() => {

            let stations = this.props.dashboardDataList;
            // console.log(stations);
            let aq1Layer = this.createLayer('AQI', true, stations, 'Two-channel PM2.5', airqualityindeximg);
            let pm1Layer = this.createLayer('PM1.0', false, stations, 'Two-channel PM1.0', airqualityindeximg);
            let pm25Layer = this.createLayer('PM2.5', false, stations, 'Two-channel PM2.5', airqualityindeximg);
            let pm10Layer = this.createLayer('PM10', false, stations, 'Two-channel PM10', airqualityindeximg);

            map.addLayer(aq1Layer);
            map.addLayer(pm25Layer);
            map.addLayer(pm10Layer);
            map.addLayer(pm1Layer);

            this.setState({
                map: map,
                refreshing: false,
                data: this.props.dashboardDataList,
                aq1Layer: aq1Layer,
                pm1Layer: pm1Layer,
                pm25Layer: pm25Layer,
                pm10Layer: pm10Layer
            });
        });
    }

    refreshingData() {
        let t = this;
        try {
            setInterval(async () => {
                try {
                    t.state.map.removeLayer(t.state.aq1Layer);
                    t.state.map.removeLayer(t.state.pm1Layer);
                    t.state.map.removeLayer(t.state.pm25Layer);
                    t.state.map.removeLayer(t.state.pm10Layer);

                    t.setData(t.state.map);

                } catch (e) {

                }

            }, 300000);
        } catch (e) {
            console.log(e);
        }
    }

    render() {

        return (
            <div id="pollution-map" className="map"/>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n.messages,
    dashboardDataList: state.list.dashboardData.data,
    waitDashboardData: state.list.dashboardData.pending,
    stationStatisticsDataList: state.list.stationStatisticsData.data,
    waitStationStatisticsData: state.list.stationStatisticsData.pending,
    stationWeekDataList: state.list.stationWeekData.data,
    waitStationSWeekData: state.list.stationWeekData.pending
});

PollutionMap = connect(mapStateToProps)(injectIntl(PollutionMap));

export default PollutionMap;
