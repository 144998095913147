import config from  '../../package.json';

const { modules } = config;

export let reducers = {};
export let headerItems = {};
export let menuReducers = [];
export let menuInitialState = {};
export let dashboardItems = [];
export let StaticRoutes = {};
export let DynamicRoutes = {};
export let Pages = {};
export let ErrorPages = {};
export let routes = {};
export let menuItems = [];
export let CustomMenuItems = [];

if (modules)
	modules.forEach((module) => {
		try {
			const content = require(`../modules/${module}/config`).default;
			reducers = {...reducers, ...content.reducers};
			headerItems = {...headerItems, ...content.headerItems};
			if (content.menuReducers) {
				menuReducers = [...menuReducers, content.menuReducers];
				menuInitialState = {...menuInitialState, ...content.menuInitialState};
			}
			if (content.dashboardItems)
				dashboardItems = [...dashboardItems, ...content.dashboardItems];
			StaticRoutes = {...StaticRoutes, ...content.StaticRoutes};
			DynamicRoutes = {...DynamicRoutes, ...content.DynamicRoutes};
			Pages = {...Pages, ...content.Pages};
			ErrorPages = {...ErrorPages, ...content.ErrorPages};
			routes = {...routes, ...content.routes};
			if (content.menuItems)
				menuItems = [...menuItems, ...content.menuItems];
			if (content.CustomMenuItems)
				CustomMenuItems = [...CustomMenuItems, ...content.CustomMenuItems];
		} catch {

		}
	});
