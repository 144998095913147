import React, {Component} from 'react';
import {
    Row, Col, FormGroup, Input, Label, InputGroup, InputGroupAddon, InputGroupText, Button, Fade
} from 'reactstrap';
import PropTypes from 'prop-types';
import L from 'leaflet';

import T from 'modules/i18n';

import Map from 'ol/Map';
import View from 'ol/View';
import {defaults as defaultInteractions, DragAndDrop, Draw} from 'ol/interaction';
import {GPX, GeoJSON, IGC, KML, TopoJSON} from 'ol/format';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {OSM, Vector as VectorSource} from 'ol/source';
import {transform} from 'ol/proj';
import {connect} from "react-redux";

const styles = {
    drop: {
        position: 'absolute',
        margin: 'auto',
        width: '20%',
        border: '3px solid white',
        padding: '10px',
        background: '#3EA662',
        textAlign: 'center',
        color: 'white',
        left: '40%',
        top: '8px'
    }
};

class OlMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            source: null,
            typeSelect: "Point",
            drawInteraction: null,
            dragAndDropInteraction: null,
            fadeIn: false
        };
        this.addDrawInteraction = this.addDrawInteraction.bind(this);
        this.handleGeometryTypeChange = this.handleGeometryTypeChange.bind(this);
        this.startDrawing = this.startDrawing.bind(this);
        this.cleanGeometry = this.cleanGeometry.bind(this);
        this.uploadGeometry = this.uploadGeometry.bind(this);
    }

    componentDidMount() {
        let raster = new TileLayer({
            source: new OSM()
        });

        let source = new VectorSource({wrapX: false});
        let vector = new VectorLayer({
            source: source
        });
        if(this.props.value.length > 0){
            source.addFeatures(this.props.value);
        }

        let map = new Map({
            layers: [raster, vector],
            target: this.props.id,
            view: new View({
                center: transform(this.props.options.position, this.props.options.EPSG, 'EPSG:3857'),
                zoom: this.props.options.zoom
            })
        });

        this.setState({
            map: map,
            source: source
        })
    }

    addDrawInteraction() {
        let value = this.state.typeSelect;
        let draw = null;
        if (value !== 'None') {
            draw = new Draw({
                source: this.state.source,
                type: value
            });
            draw.on('drawstart', () => {
                this.state.source.clear();
            });
            draw.on('drawend', (e) => {
                this.state.map.removeInteraction(draw);
                this.props.onChange({target: {name: 'features', value: [e.feature]}});
            });
            this.state.map.addInteraction(draw);
        }
        this.setState({
            drawInteraction: draw
        })
    }

    handleGeometryTypeChange(e) {
        let type = e.target.value;
        this.setState({
            typeSelect: type
        }, function () {
            this.state.map.removeInteraction(this.state.drawInteraction);
        });
    }

    startDrawing(e) {
        e.preventDefault();
        try {
            this.state.map.removeInteraction(this.state.dragAndDropInteraction);
        } catch (e) {
        }
        if (this.state.fadeIn) {
            this.toggle();
        }
        this.addDrawInteraction();
    }

    cleanGeometry(e) {
        e.preventDefault();
        try {
            this.state.map.removeInteraction(this.state.drawInteraction);
        } catch (e) {
        }
        this.state.source.clear();
        if (this.state.fadeIn) {
            this.toggle();
        }
    }

    uploadGeometry(e) {
        e.preventDefault();
        try {
            this.state.map.removeInteraction(this.state.drawInteraction);
        } catch (e) {
        }
        this.state.source.clear();
        this.addDragAndDropInteraction();
        if (!this.state.fadeIn) {
            this.toggle();
        }
    }

    addDragAndDropInteraction() {
        let t = this;
        let dragAndDropInteraction = new DragAndDrop(
            {
                formatConstructors:
                    [
                        GPX,
                        GeoJSON,
                        IGC,
                        KML,
                        TopoJSON
                    ]
            });

        dragAndDropInteraction.on('addfeatures', function (event) {
            if (event.features.length > 1) {
                alert(t.props.i18n['geometry alert']);
            } else {
                t.state.source.addFeatures(event.features);
                t.state.map.getView().fit(t.state.source.getExtent());
                t.props.onChange({target: {name: 'features', value: t.state.source.getFeatures()}});
            }
            t.state.map.removeInteraction(dragAndDropInteraction);
            if (t.state.fadeIn) {
                t.toggle();
            }
        });
        this.state.map.addInteraction(dragAndDropInteraction);
        this.setState({
            dragAndDropInteraction: dragAndDropInteraction
        })

    }

    toggle() {
        this.setState({
            fadeIn: !this.state.fadeIn
        });
    }

    render() {

        const {id} = this.props;

        return (
            <FormGroup>
                <Row>
                    <Col xs="12" md="6" lg="4">
                        <FormGroup row>
                            <Label htmlFor="geometry_type" xs="12" sm="12" md="12" lg="2" xl="2"><T>draw</T></Label>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                                <Input id="geometry_type" type="select" name="geometry_type"
                                       onChange={this.handleGeometryTypeChange} value={this.state.typeSelect}>
                                    <option value="Point">{this.props.i18n['point']}</option>
                                    <option value="LineString">{this.props.i18n['line']}</option>
                                    <option value="Polygon">{this.props.i18n['polygon']}</option>
                                </Input>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="3" xl="3">
                                <Button onClick={this.startDrawing} color="success" style={{width: '100%'}}><T>draw</T></Button>
                            </Col>
                            <Col xs="12" sm="12"md="12" lg="3" xl="3">
                                <Button onClick={this.cleanGeometry} color="danger"
                                        style={{width: '100%'}}><T>remove</T></Button>
                            </Col>
                        </FormGroup>
                        <hr/>
                        <p><T>drag and drop text</T></p>
                        <Button id="upload_geometry" onClick={this.uploadGeometry} color="success"><T>drag and drop</T></Button>
                        <hr/>
                    </Col>
                    <Col sm="12" md="6" lg="8">
                        <div id={id}/>
                        <Fade in={this.state.fadeIn} style={styles.drop} id="dropmap"><T>drag and drop instructions</T></Fade>
                    </Col>
                </Row>
            </FormGroup>
        );
    }

}

OlMap.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    options: PropTypes.object,
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    i18n: state.i18n.messages,
});

OlMap = connect(mapStateToProps)(OlMap);

export default OlMap;
