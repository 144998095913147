import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'grouping',
		url: StaticRoutes.Groups,
		icon: 'fa fa-group',
		role: roles.EDITOR
	},
];

export default menu;
