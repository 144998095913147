import * as roles from 'core/model/roles';

/**
 * Static routes
 */

// Admin
const Typologies = '/admin/typologies';
const TypologyCreate = '/admin/typologies/create';
const TypologyList = '/admin/typologies/list';
const Toolbox = '/admin/toolbox';

export const StaticRoutes = {
	Typologies,
	TypologyCreate,
	TypologyList,
	Toolbox
};

/**
 * Dynamic routes
 */

const TypologyEdit = Typologies + '/token/:token';
// const TypologyEditNode = '/admin/typology/:token/node/:node';

export const DynamicRoutes = {
	TypologyEdit,
};

export const routes = {
	[Typologies]: {
		name: 'typologies',
		to: TypologyList,
		exact: true,
	},
	[TypologyList]: {
		name: 'list',
		component: 'Typologies',
		role: roles.ADMIN,
	},
	[TypologyCreate]: {
		name: 'create',
		component: 'CreateTypology',
		exact: true,
		role: roles.ADMIN,
	},
	[TypologyEdit]: {
		name: 'define',
		component: 'DefineTypology',
		role: roles.ADMIN,
	},
	[Toolbox]: {
		name: 'toolbox',
		component: 'Typologies',
		role: roles.ADMIN
	}
};

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
