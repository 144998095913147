import React, { Component } from 'react';
import {
	Card,
	CardBody,
	Table as ReactstrapTable
} from 'reactstrap';

const TableContext = React.createContext();

export const TableContextConsumer = TableContext.Consumer;

class Table extends Component {

	constructor(props) {
		super(props);
		this.state = {
			toolboxIsOpen: false,
			query: '',
			filterValue: {},
			registerPending: false
		};
		this.onToolboxButtonClick = this.onToolboxButtonClick.bind(this);
		this.onResetFiltersClick = this.onResetFiltersClick.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.registerFilter = this.registerFilter.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
	}

	onToolboxButtonClick() {
		this.setState({toolboxIsOpen: !this.state.toolboxIsOpen});
	}

	onResetFiltersClick() {
		let filterValue = {};
		Object.keys(this.state.filterValue).forEach((scope) => {
			filterValue = {...filterValue, [scope]: ''};
		});
		this.setState({
			query: '',
			filterValue
		});
	}

	onSearchChange(event) {
		this.setState({query: event.target.value});
	}

	registerFilter(scope, value='') {
		let promise = new Promise((resolve, reject) => {
			if (!this.state.registerPending)
				resolve();
		});
		promise.then(() => {
			this.setState({
				registerPending: true
			}, () => {
				this.setState({
					filterValue: {...this.state.filterValue, [scope]: value},
					registerPending: false
				});
			});
		});
	}

	onFilterChange(scope, event) {
		this.setState({
			filterValue: {...this.state.filterValue, [scope]: event.target.value}
		});
	}

	render() {
		let pre_children = [];
		let table_children = [];
		let post_children = [];
		let pre = true;
		React.Children.map(this.props.children, (child) => {
			let name = child.type.displayName;
			if (['Thead', 'Tbody', 'Tfooter'].indexOf(name) !== -1) {
				table_children.push(child);
				pre = false;
			} else {
				if (pre) {
					pre_children.push(child);
				} else {
					post_children.push(child);
				}
			}
		});

		return (
			<TableContext.Provider value={{
				onToolboxButtonClick: this.onToolboxButtonClick,
				toolboxIsOpen: this.state.toolboxIsOpen,
				onResetFiltersClick: this.onResetFiltersClick,
				onSearchChange: this.onSearchChange,
				query: this.state.query,
				registerFilter: this.registerFilter,
				onFilterChange: this.onFilterChange,
				filterValue: this.state.filterValue,
			}}>
				<Card>
					{pre_children}
					<CardBody>
						<ReactstrapTable responsive striped>
							{table_children}
						</ReactstrapTable>
						{post_children}
					</CardBody>
				</Card>
			</TableContext.Provider>
		);
	}
}

Table.displayName = 'Table';

export default Table;
