import React, { Component } from 'react';
import { connect } from 'react-redux';
import Calendar from 'react-calendar';
import { Row, Col } from 'reactstrap';
import { DragDropContext } from 'react-beautiful-dnd';

import { requestData } from 'core/ducks/list';
import { Loading } from 'core/components';

import { User, List } from '../../components';

class Grouping extends Component {

	constructor(props) {
		super(props);
		this.state = {
			date: new Date(),
			users: []
		};
		this.handleCalendarChange = this.handleCalendarChange.bind(this);
	}

	handleCalendarChange(date) {
		this.setState({date});
	}

	componentDidMount() {
		this.props.dispatch( requestData('users', 'users/limit/1000/fq/active:true;role:reviewer') );
	}

	componentDidUpdate(prevProps) {
		const { users } = this.props;
		if ((!prevProps.users.values && users.values) || (prevProps.users.values.length !== users.values.length)) {
			this.setState({ users: this.prepareUsersList(this.props.users.values)});
		}
	}

	prepareUsersList(items) {
		return Object.keys(items).map(id => {
			let item = items[id];
			item.id = id;
			return item;
		});
	}

	render() {
		const { pending } = this.props;
		const { locale } = this.props.i18n || {locale: 'en'};
		if (pending)
			return <Loading />;

		return (
			<>
				<div>
					<Calendar
						onChange={this.handleCalendarChange}
						value={this.state.date}
						locale={locale}
						className="m-auto"
					/>
				</div>
				<Row>
					<Col>
						<DragDropContext onDragEnd={() => {}}>
							<List items={this.state.users} droppableId="droppable_area_1">
								{this.state.users.map(user =>
									<User key={`user_${user.id}`} id={user.id} username={user.username} firstname={user.firstname} lastname={user.lastname} />
								)}
							</List>
						</DragDropContext>
					</Col>
					<Col>
					</Col>
				</Row>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	users: state.list.users.data,
	usersPending: state.list.users.pending,
});

Grouping = connect(mapStateToProps)(Grouping);

export default Grouping;