import * as roles from 'core/model/roles';

/**
 * Static routes
 */

// Admin
const Groups = '/admin/groups';

export const StaticRoutes = {
	Groups,
};

/**
 * Dynamic routes
 */
// const EditFormsDynamic = EditForms + '/form/:form';
// const EditWorkflowsDynamic = EditWorkflows + '/workflow/:workflow';
// const PdfTemplateDynamic = PdfTemplates + '/workflow/:workflow';

// export const DynamicRoutes = {
// 	EditWorkflows: EditWorkflowsDynamic,
// 	EditForms: EditFormsDynamic,
// 	PdfTemplates: PdfTemplateDynamic,
// };

export const routes = {
	[Groups]: {
		name: 'grouping',
		component: 'Grouping',
		role: roles.EDITOR,
	},
};

const combined = {StaticRoutes, routes};

export default combined;
