import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'toolbox',
		url: StaticRoutes.Toolbox,
		icon: 'icon-wrench',
		role: roles.ADMIN,
	},
	{
		name: 'typologies',
		url: StaticRoutes.Typologies,
		icon: 'icon-directions',
		role: roles.ADMIN,
		children: [
			{
				name: 'list',
				icon: 'icon-list',
				url: StaticRoutes.TypologyList
			},
			{
				name: 'create',
				icon: 'icon-plus',
				url: StaticRoutes.TypologyCreate,
				role: roles.ADMIN
			}
		]
	},
];

export default menu;
