import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'thermiairDashboard',
		url: StaticRoutes.Dashboard,
		role: roles.GUEST
	},
	{
		name: 'thermiairMap',
		url: StaticRoutes.PollutionMap,
		role: roles.GUEST
	},
	{
		name: 'thermiairVariation',
		url: StaticRoutes.PollutionVariation,
		role: roles.GUEST
	},
	{
		name: 'thermiairStatistics',
		url: StaticRoutes.Statistics,
		role: roles.GUEST
	},
	{
		name: 'thermiairCams',
		url: StaticRoutes.Cams,
		role: roles.GUEST
	}
];

export default menu;
