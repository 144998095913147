import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Container,
    Row,
    Col,
    CardGroup,
    Card,
    CardBody,
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';
import * as dc from "dc";

import airqualityindeximg from 'core/views/layout/img/airqualityindex.jpg';
import Options from "./dc/options";
import StationChart from "./dc/stationChart";
import StationChart1 from "./dc/stationChart1";
import StationChart10 from "./dc/stationChart10";
import DayChart from "./dc/dayChart";
import DayChart1 from "./dc/dayChart1";
import DayChart10 from "./dc/dayChart10";
import HourChart from "./dc/hourChart";
import HourChart1 from "./dc/hourChart1";
import HourChart10 from "./dc/hourChart10";
import {DataContext} from "./dc/cxContext";

import './css/thermiair.css';


class Statistics extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1'
        };

        this.tabs = [{id: '1', title: 'PM1.0'}, {id: '2', title: 'PM2.5'}, {id: '3', title: 'PM10'}];
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        dc.redrawAll();
        dc.renderAll();
    }

    render() {

        return (
            <div className="app-container flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        {true ? <Col>Υπό κατασκευή...</Col> :
                            <Col md="12">
                                <CardGroup>
                                    <Card>
                                        <CardBody className="text-center">

                                            <div>

                                                <DataContext>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Options/>
                                                        </Col>
                                                    </Row>


                                                    <Nav tabs>
                                                        {this.tabs.map((item) =>
                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames({active: this.state.activeTab === item.id})}
                                                                    onClick={() => {
                                                                        this.toggle(item.id);
                                                                    }}
                                                                >
                                                                    <b>{item.title}</b>
                                                                </NavLink>
                                                            </NavItem>
                                                        )}
                                                    </Nav>
                                                    <TabContent activeTab={this.state.activeTab}>
                                                        <TabPane tabId="1">
                                                            <Row>
                                                                <Col md={8}>
                                                                    <StationChart1/>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <img src={airqualityindeximg} width={200}
                                                                         alt={'legend'} style={{paddingTop: '3em'}}/>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <DayChart1/>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <HourChart1/>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="2">
                                                            <Row>
                                                                <Col md={8}>
                                                                    <StationChart/>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <img src={airqualityindeximg} width={200}
                                                                         alt={'legend'} style={{paddingTop: '3em'}}/>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <DayChart/>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <HourChart/>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="3">
                                                            <Row>
                                                                <Col md={8}>
                                                                    <StationChart10/>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <img src={airqualityindeximg} width={200}
                                                                         alt={'legend'} style={{paddingTop: '3em'}}/>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <DayChart10/>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <HourChart10/>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                    </TabContent>


                                                </DataContext>

                                            </div>
                                        </CardBody>
                                    </Card>
                                </CardGroup>
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
});

Statistics = connect(mapStateToProps)(Statistics);

export default Statistics;
