import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Row,
	Col,
} from 'reactstrap';

import {
	Filter,
	FilterGroup,
	Table,
	Title,
	Button,
	Tbody,
	Thead,
	Toolbox,
	Tr,
	Search,
	Pagination,
} from 'table';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { Loading, SecureContent, Error } from 'core/components';
import { ExportToCSV } from 'core/model/lib';
import * as roles from 'core/model/roles';
import { applicationStatus } from 'core/model/constants';
import Alert from 'core/views/modals/alert';
import View from 'core/views/modals/view';
import { toggleModal } from 'core/ducks/ui/modal';
import { deleteData, getData as getElement, updateData as activate } from 'core/ducks/update';
import T from 'modules/i18n';

class WorkflowsList extends Component {

	constructor(props) {
		super(props);
		this.initialState = {
			query: '',
			status: '',
			sort: 'created',
			sort_method: 'desc',
		};
		this.state = {...this.initialState, page: 1, refreshing: false, mounted: true};
		this.fields = props.type==='private'
			? ['name', 'created', 'step', 'status']
			: ( props.profile.role===roles.GUEST
				? ['name', 'registry_number', 'created', 'revision', 'step', 'status']
				: ['workflow', 'registry_number', 'arithmos_fakelou', 'defteros_arithmos_fakelou', 'author', 'created', 'last_action', 'revision', 'step', 'reviewer', 'status']
			);
		this.layout = {
			workflow: {sortable: true},
			registry_number: {sortable: true},
			author: {sortable: true},
			created: {type: 'date', sortable: true},
			last_action: {type: 'date', sortable: true},
			revision: {sortable: true},
			step: {sortable: true},
			reviewer: {sortable: true},
			status: {type: 'translatable'}
		};
		this.badge_colors = {
			'rejected': 'red',
			'accepted': 'green',
		}

		this.actions = bindActionCreators({toggleModal, deleteData}, props.dispatch);

		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
		this.requestData = this.requestData.bind(this);
		this.createUrl = this.createUrl.bind(this);
		this.viewDetails = this.viewDetails.bind(this);
		this.activate = this.activate.bind(this);
	}

	handlePageChange(page) {
		this.setState({ page }, this.requestData);
	}

	handleSelect(uuid) {
		const path = buildPath(DynamicRoutes.Apply, [uuid, 'current']);
		this.props.history.push(path);
	}

	handleFilterChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
			page: 1
		});

		if (this.props.match && this.props.match.params) {
			const target = event.target;
			let { status } = this.props.match.params;
			let url = StaticRoutes.AdminWorkflowsList;
			status = target.name==='status' ? (target.value ? target.value : undefined) : status;
			url += status ? `/status/${status}` : '';
			this.props.history.push(url);
		}
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.requestData);
		this.props.history.push(StaticRoutes.AdminWorkflowsList);
	}

	handleCSVExport() {
		const { messages } = this.props.i18n || {messages: {}};
		const url = this.createUrl('csv');
		this.props.dispatch(requestData('csv', url)).then(() => {
			let data = Object.keys(this.props.list.csv.data.values).map((index) => {
				let row = this.props.list.csv.data.values[index];
				return this.fields.map((col) => {
					return (this.layout[col] && this.layout[col].type === 'translatable' && messages[row[col]]) ? messages[row[col]] : row[col];
				});
			});
			let fields = this.fields.map((field) => {
				return messages[field] ? messages[field] : field;
			});
			let csv = new ExportToCSV('users.csv', fields, data);
			csv.createLink();
		});
	}

	handleSortChange(sort) {
		if (sort===this.state.sort) {
			this.setState({ sort_method: this.state.sort_method==='asc' ? 'desc' : 'asc'}, this.requestData);
		} else {
			this.setState({ sort }, this.requestData);
		}
	}

	createUrl(scope='') {
		let { type, profile } = this.props;
		if ( !type || (type !== 'public' && type !== 'private') )
			type = 'public';
		let { query, sort, sort_method } = this.state;
		let status = (this.props.match && this.props.match.params) ? this.props.match.params.status : undefined;
		let url = `application/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
		url += this.props.inactive ? '/inactive/true' : '';
		query = (query !== '') ? '/query/' + query : query;
		let fq = `/fq/type:${type}`;
		fq += status ? `;status:${status}` : '';
		const role = profile.role===roles.ADMIN ? roles.EDITOR : (profile.role===roles.GUEST ? 'author' : profile.role);
		fq += this.props.personal ? `;${role}:${profile.uuid}` : '';
		url += query + fq;
		if (scope === 'csv')
			url += '/limit/100000';

		return url;
	}

	requestData() {
		if (!this.state.mounted)
			return;
		const { dispatch } = this.props;
		const url = this.createUrl();
		this.setState({refreshing: true}, () => {
			dispatch(requestData('application', url)).then(() => {
				this.setState({refreshing: false});
			});
		});
	}

	viewDetails(uuid) {
		this.props.dispatch( getElement(`application/inactive/true/view/${uuid}`) ).then(() => {
			if (this.props.http_status === 200)
				this.actions.toggleModal(true,
					<View
						title="application details"
						toggle={this.actions.toggleModal}
						data={this.props.view}
						translateFields={false}
					/>
				);
		});
	}

	activate(uuid) {
		this.props.dispatch( activate('application/inactive/activate', {'uuid': uuid}, false) ).then(() => {
			if (this.props.http_status === 200) {
				const path = buildPath(DynamicRoutes.Apply, [uuid, 'current']);
				this.props.history.push(path);
			}
		});
	}

	componentDidMount() {
		if (this.props.match && this.props.match.params.status) {
			this.setState({status: this.props.match.params.status}, this.requestData);
		} else {
			this.requestData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { params } = this.props.match;
		if ( params.status !== prevProps.match.params.status || prevState.query !== this.state.query) {
			let state = {page: 1};
			if (prevState.query !== this.state.query)
				state = Object.assign(state, {query: this.state.query});
			if (params.status !== prevProps.match.params.status)
				state = Object.assign(state, {status: params.status})
			this.setState(state, this.requestData());
		}
		if (prevProps.inactive !== this.props.inactive)
			this.setState(this.initialState, this.requestData());
		if (prevProps.modalOpen !== this.props.modalOpen && !this.props.modalOpen)
			this.requestData();
	}

	componentWillUnmount() {
		this.setState({...this.initialState, page: 1, refreshing: false, mounted: false});
	}

	render() {
		const { data, info, list } = this.props;
		if (this.props.pending || !data)
			return (<Loading />);
		if (list.application.status !== 200 && list.application.status !== '')
			return (<Error status={list.application.status} errorMsg={list.application.errorMsg} />);
		if (Object.keys(data).length === 0)
			return (
				<Row>
					<Col className="text-center">
						{ this.props.inactive ?
							<T>empty inactive</T>
							:
							<T>empty workflows</T>
						}
					</Col>
				</Row>
			);

		const { messages } = this.props.i18n || {messages: {}};

		return (
			<React.Fragment>
				<Row>
					<Col>
						<Table>
							<Title>
								<T>{this.props.inactive ? 'inactive_workflows' : 'applications'}</T>
								<SecureContent role={roles.REVIEWER}>
									<Button type="toolbox" title="filters" className="float-right"/>
								</SecureContent>
							</Title>
							<SecureContent role={roles.REVIEWER}>
								<Toolbox onReset={this.handleToolboxReset}>
									<Col xs="12" lg="8" className="form-group text-right">
										<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
										<Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
											<T>export</T> csv
										</Button>
										<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
									</Col>
									<Col xs="12" lg="4">
										<FilterGroup>
											<Filter onChange={this.handleFilterChange} name="status" defaultValue={this.state.status} >
												<option value="">{`${messages.choose || 'choose'} ${messages.status || 'status'}`}</option>
												{ applicationStatus.map((status) => (
													<option key={`option_${status}`} value={status}>{messages.status || 'status'}</option>
												))}
											</Filter>
										</FilterGroup>
									</Col>
								</Toolbox>
							</SecureContent>
							<Thead>
								<Tr
									className="text-capitalize"
									data={(this.props.personal || this.props.inactive) ? ['', ...this.fields] : this.fields}
									layout={this.layout}
									sortBy={this.state.sort}
									sortMethod={this.state.sort_method}
									onClick={this.handleSortChange}
								/>
							</Thead>
							<Tbody refreshing={this.state.refreshing}>
								{ this.props.personal ?
									<Tr
										data={data ? data : {}}
										layout={this.layout}
										order={this.fields}
										badge_colors={this.badge_colors}
									>
										<Button type="edit" title={messages.edit || 'edit'} onClick={(data) => {this.handleSelect(data.index)}} />
										<Button
											type="drop"
											title={messages.drop || 'drop'}
											disabled={data => data.status!=='progressing'}
											onClick={
												index => this.actions.toggleModal(true,
													<Alert
														toggle={this.actions.toggleModal}
														title="drop confirm"
														message="do you wish to continue"
														onConfirm={() => this.actions.deleteData(`application/uuid/${index}`)}
													/>
												)
											}
										/>
									</Tr>
									: this.props.inactive ?
									<Tr
										data={data ? data : {}}
										layout={this.layout}
										order={this.fields}
										badge_colors={this.badge_colors}
									>
										<Button type="view" title={messages.view || 'view'} onClick={(data) => {this.viewDetails(data.index)}} />
										<Button type="activate" title={messages.activate || 'activate'} onClick={(index) => {this.activate(index)}} />
										<Button type="drop" title={messages.drop || 'drop'} onClick={
											index => this.actions.toggleModal(true,
													<Alert
														toggle={this.actions.toggleModal}
														title="drop confirm"
														message="do you want to permanently delete this application"
														onConfirm={() => this.actions.deleteData(`application/inactive/true/uuid/${index}`)}
													/>
												)
										} />
									</Tr>
									:
									<Tr
										data={data ? data : {}}
										layout={this.layout}
										order={this.fields}
										onRowClick={this.props.personal ? undefined : this.handleSelect}
										badge_colors={this.badge_colors}
									/>
								}
							</Tbody>
							<Pagination
								className="mx-auto"
								page={info.page}
								total={info.total_pages}
								onClick={(page) => {
									if (page !== info.page)
										this.handlePageChange(page);
								}}
							/>
						</Table>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.user,
	list: state.list,
	pending: state.list.application.pending,
	data: getData(state, 'application'),
	info: getInfo(state, 'application'),
	modalOpen: state.ui.modal.modalOpen,
	view: state.update.response,
	http_status: state.update.status,
	i18n: state.i18n,
});

WorkflowsList = connect(mapStateToProps)(WorkflowsList);

export default WorkflowsList;
