import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const grid = 8;

const getListStyle = isDraggingOver => ({
	// background: isDraggingOver ? 'lightblue' : 'lightgrey',
	// padding: grid,
	// width: 250
});

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	// userSelect: 'none',
	// padding: grid * 2,
	// margin: `0 0 ${grid}px 0`,

	// change background colour if dragging
	// background: isDragging ? 'lightgreen' : 'grey',
	width: 'auto',
	borderColor: 'green',
	borderWidth: 5 + 'px',
	borderStyle: 'solid',

	// styles we need to apply on draggables
	...draggableStyle
});

class List extends Component {

	constructor(props) {
		super(props);
		this.state = {
			items: props.items,
		};
	}

	render() {
		return (
			<Droppable droppableId={this.props.droppableId}>
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}>
						{React.Children.map(this.props.children, (item, index) => (
							<Draggable
								key={item.props.id}
								draggableId={item.props.id}
								index={index}
							>
								{(provided, snapshot) => {
									const Child = React.cloneElement(item, {className: 'row-selected'})
									return (
										<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
											{Child}
										</div>
									);
								}}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		);
	}
}

List.propTypes = {
	droppableId: PropTypes.string.isRequired,
	items: PropTypes.array,
};

export default List;
