import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Row,
    Col,
    Card,
    CardBody,
    Table,
} from 'reactstrap';
import {FormattedDate, injectIntl} from 'react-intl';

import {Api} from 'core/api';
import {DynamicRoutes} from 'flows-app/model/routes';
import {buildPath} from 'core/model/lib/urlTools';
import {requestData} from 'core/ducks/list';
import {getData} from 'core/ducks/update';
import T from 'modules/i18n';

import {defaults as defaultControls, OverviewMap, FullScreen} from "ol/control";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import OSM from "ol/source/OSM";
import Stamen from "ol/source/Stamen";
import View from "ol/View";
import GroupLayer from "ol/layer/Group";
import {transform} from 'ol/proj';

import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';

import GeoBookmark from "ol-ext/control/GeoBookmark";
import SearchGPSControl from "ol-ext/control/SearchGPS";
import LayerSwitcher from "../../components/layercontrol/LayerSwitcher";
import "../../components/layercontrol/LayerSwitcher.css";
import ZoomAll from "../../components/zoomall/zoomall";

import '../../css/css.css';

const mapConfig = {
    projection: 'EPSG:3857',
    center: transform([23.7015375, 37.9959723], 'EPSG:4326', 'EPSG:3857'),
    zoom: 12,
    minZoom: 5,
    extent: [2107931.893, 4091876.540, 3406749.877, 5141204.064]
};

const mapLayers = [
    {url: 'https://ppcity.getmap.gr/geoserver/ppathens/wms', name:'v_athens_perioxi_soap', title:'Περιοχή ΣΟΑΠ Δ. Αθηναίων', visible: false, extent: [2615320.886, 4556637.558, 2675629.702, 4595238.258], opacity: 1},
    {url: 'https://ppcity.getmap.gr/geoserver/ppathens/wms', name:'v_athens_perioxi_sbaa', title:'Περιοχή ΣΒΑΑ Δ. Αθηναίων', visible: false, extent: [2615320.886, 4556637.558, 2675629.702, 4595238.258], opacity: 1},
    {url: 'https://ppcity.getmap.gr/geoserver/ppathens/wms', name:'v_athens_alsi', title:'Άλση Δ. Αθηναίων', visible: true, extent: [2615320.886, 4556637.558, 2675629.702, 4595238.258], opacity: 1},
    {url: 'https://ppcity.getmap.gr/geoserver/ppathens/wms', name:'v_proastiakos_line', title:'Γραμμές Προαστιακού Σιδηροδρόμου', visible: true, extent: [2615320.886, 4556637.558, 2675629.702, 4595238.258], opacity: 1},
    {url: 'https://ppcity.getmap.gr/geoserver/ppathens/wms', name:'v_athens_roads_in', title:'Δρόμοι Δ. Αθηναίων', visible: false, extent: [2615320.886, 4556637.558, 2675629.702, 4595238.258], opacity: 1},
    {url: 'https://ppcity.getmap.gr/geoserver/ppathens/wms', name:'v_athens_xvroi_prasinou', title:'Χώροι Πρασίνου Δ. Αθηναίων', visible: true, extent: [2615320.886, 4556637.558, 2675629.702, 4595238.258], opacity: 1},
    {url: 'https://ppcity.getmap.gr/geoserver/ppathens/wms', name:'v_athens_fyteyseis_dhmou', title:'Φυτεύσεις Δ. Αθηναίων', visible: true, extent: [2615320.886, 4556637.558, 2675629.702, 4595238.258], opacity: 1},
    {url: 'https://ppcity.getmap.gr/geoserver/ppathens/wms', name:'v_proastiakos_stations', title:'Σταθμοί Προαστιακού Σιδηροδρόμου', visible: true, extent: [2615320.886, 4556637.558, 2675629.702, 4595238.258], opacity: 1}
];

const basemapLayers = new GroupLayer({ title: 'Basemaps', openInLayerSwitcher: false, baseLayer: true, layers: [
        new TileLayer({id: 'OpenStreetMap', title: 'OpenStreetMap', baseLayer: true, visible: true, source: new OSM()}),
        new TileLayer({id: 'Watercolor', title: "Watercolor", baseLayer: true,visible: false,source: new Stamen({ layer: 'watercolor' })}),
        new TileLayer({id: 'Toner', title: "Toner", baseLayer: true, visible: false, source: new Stamen({ layer: 'toner' })}),

]});

const bookmarks = {
    Αθήνα: {pos: transform([23.7015375, 37.9959723], 'EPSG:4326', 'EPSG:3857'), zoom: 12, permanent: true},
    Θεσσαλονίκη: {pos: transform([22.9544672, 40.6408682], 'EPSG:4326', 'EPSG:3857'), zoom: 12, permanent: true}
};

class PPcityMap extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    componentDidMount() {

        let layers = [];

        layers.push(basemapLayers);
        mapLayers.map((e)=>{
           layers.push(
               new TileLayer({
                   id: e.name,
                   title: e.title,
                   baseLayer: false,
                   visible: e.visible,
                   extent: e.extent,
                   source: new TileWMS({
                       url: e.url,
                       params: {
                           LAYERS: e.name
                       }
                   }),
                   opacity: e.opacity
               })
           )
        });

        let map_view = new View(mapConfig);
        let lswitcher = new LayerSwitcher({
            show_progress:true,
            extent: true
        });
        let searchGPS = new SearchGPSControl({});
        let map = new Map({
            layers: layers,
            controls: defaultControls({attribution: false}).extend([
                lswitcher,
                new OverviewMap(),
                new FullScreen(),
                searchGPS,
                new ZoomAll({xy: mapConfig.center, zoom: mapConfig.zoom, extra: -1}),
            ]),
            target: 'ppcity-map',
            view: map_view
        });

        // Select feature when click on the reference index
        searchGPS.on('select', function(e){
            console.log(e);
            map.getView().animate({
                center:e.search.coordinate,
                zoom: Math.max (map.getView().getZoom(),5)
            });
        });

        let bm = new GeoBookmark({
            marks:bookmarks
        });
        map.addControl(bm);

    }


    render() {

        return (
            <Row>
                <Col>
                    <Card className="m-0">
                        <CardBody style={{padding: 0}}>
                            <div id={"ppcity-map"}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

}

const mapStateToProps = (state) => ({
    i18n: state.i18n.messages,
    list: state.list,
    info: state.update.response,
    data_pending: state.update.pending,
    queries: state.ui.menu.asideState,
});

PPcityMap = connect(mapStateToProps)(injectIntl(PPcityMap));

export default PPcityMap;
