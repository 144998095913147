import React, {Component} from "react";
import * as dc from "dc";
import {scaleBand, scaleLinear, scaleOrdinal} from "d3";
import {ChartTemplate} from "./chartTemplate";
import HourChart from "./hourChart";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";

class DayChart1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pollutant: null
        };
        this.dayChartFunc = this.dayChartFunc.bind(this);
    }

    dayChartFunc(divRef, ndx){
        let t = this;
        let pollutant = 'PM1';
        let dayChart = dc.barChart(divRef)
        const name = [t.props.i18n['Sun'], t.props.i18n['Mon'], t.props.i18n['Tue'], t.props.i18n['Wed'], t.props.i18n['Thu'], t.props.i18n['Fri'],  t.props.i18n['Sat']];
        let dayDimension = ndx.dimension(function(d) {
            return d.day + '.' + name[d.day];
        });
        let avgGroupPM25 = dayDimension.group().reduce(
            function (p, v) {
                ++p.count;
                p.total += v[pollutant];
                return p;
            },
            function (p, v) {
                --p.count;
                p.total -= v[pollutant];
                return p;
            },
            function () {
                return {count: 0, total: 0};
            }
        );
        dayChart
            .x(scaleBand())
            .y(scaleLinear().domain([0, 80]))
            .xUnits(dc.units.ordinal)
            .brushOn(false)
            .xAxisLabel(' ')
            .yAxisLabel('μg/m³')
            .dimension(dayDimension)
            .group(avgGroupPM25)
            .valueAccessor(function(p) { return p.value.count > 0 ? Math.round(p.value.total / p.value.count * 100) / 100 : 0; })
            .colorAccessor(function (p) {
                let tmp = p.value.count > 0 ? Math.round(p.value.total / p.value.count * 100) / 100 : 0;
                if (tmp < 10) {
                    return "class1";
                } else if (tmp >= 10 && tmp < 20) {
                    return "class2";
                } else if (tmp >= 20 && tmp < 25) {
                    return "class3";
                } else if (tmp >= 25 && tmp < 50) {
                    return "class4";
                } else if (tmp >50) {
                    return "class5";
                }
            })
            .colors(scaleOrdinal().domain(["class1", "class2", "class3", "class4", "class5"])
                .range(["#5ED5FF", "#92D14F", "#FFFF00", "#FC3903", "#990100"]));

        dayChart.renderlet(function (chart) {
            // rotate x-axis labels
            chart.selectAll('g.x text')
                .attr('transform', 'translate(-10,10) rotate(315)');
        });

        return dayChart
    }

    render() {
        return (
            <ChartTemplate chartFunction={this.dayChartFunc} resetFunction={true} title={this.props.i18n['thermiairAvgDay']}/>
        )
    }

}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n.messages
});

DayChart1 = connect(mapStateToProps)(injectIntl(DayChart1));

export default DayChart1;

