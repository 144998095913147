import React, {Component} from 'react';
import {connect} from 'react-redux';
import {defaults as defaultControls} from "ol/control";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import OSM from "ol/source/OSM";
import View from "ol/View";
import 'ol-ext/dist/ol-ext.css';
import LayerPopup from 'ol-ext/control/LayerPopup';
import LegendInfoControl from '../../components/legend/legend';
import TimeSlider from '../../components/timeslider/timeslider';
import moment from 'moment';


class Cams extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            min: moment().startOf('hour').valueOf(),
            max: moment().add(2, 'days').startOf('hour').valueOf(),
            step: 3600000
        };
    }

    componentDidMount() {
        let LegendControl = new LegendInfoControl();
        let osmLayer = new TileLayer({
            source: new OSM()
        });
        let pm25 = new TileLayer({
            id: "pm2.5",
            title: "PM2.5",
            baseLayer: true,
            visible: true,
            source: new TileWMS({
                url: 'https://geoservices.regional.atmosphere.copernicus.eu/services/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS',
                params: {
                    LAYERS: 'PM25__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND',
                    token: '__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__'
                }
            }),
            legend: 'https://geoservices.meteofrance.fr/api/__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS?service=WMS&version=1.3.0&sld_version=1.1.0&request=GetLegendGraphic&layer=PM25__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND&style=PM25_USI__HEIGHT__SHADING&format=image/png',
            opacity: 0.7
        });
        let pm10 = new TileLayer({
            id: "pm10",
            title: "PM10",
            baseLayer: true,
            visible: false,
            source: new TileWMS({
                url: 'https://geoservices.regional.atmosphere.copernicus.eu/services/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS',
                params: {
                    LAYERS: 'PM10__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND',
                    token: '__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__'
                }
            }),
            legend: 'https://geoservices.meteofrance.fr/api/__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS?service=WMS&version=1.3.0&sld_version=1.1.0&request=GetLegendGraphic&layer=PM10__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND&style=PM10_USI__HEIGHT__SHADING&format=image/png',
            opacity: 0.7
        });
        let no = new TileLayer({
            id: "no",
            title: "NO",
            baseLayer: true,
            visible: false,
            source: new TileWMS({
                url: 'https://geoservices.regional.atmosphere.copernicus.eu/services/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS',
                params: {
                    LAYERS: 'NO__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND',
                    token: '__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__'
                }
            }),
            legend: 'https://geoservices.meteofrance.fr/api/__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS?service=WMS&version=1.3.0&sld_version=1.1.0&request=GetLegendGraphic&layer=NO__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND&style=NO_USI__HEIGHT__SHADING&format=image/png',
            opacity: 0.7
        });
        let no2 = new TileLayer({
            id: "no2",
            title: "NO2",
            baseLayer: true,
            visible: false,
            source: new TileWMS({
                url: 'https://geoservices.regional.atmosphere.copernicus.eu/services/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS',
                params: {
                    LAYERS: 'NO2__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND',
                    token: '__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__'
                }
            }),
            legend: 'https://geoservices.meteofrance.fr/api/__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS?service=WMS&version=1.3.0&sld_version=1.1.0&request=GetLegendGraphic&layer=NO2__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND&style=NO2_USI__HEIGHT__SHADING&format=image/png',
            opacity: 0.7
        });
        let co = new TileLayer({
            id: "co",
            title: "CO",
            baseLayer: true,
            visible: false,
            source: new TileWMS({
                url: 'https://geoservices.regional.atmosphere.copernicus.eu/services/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS',
                params: {
                    LAYERS: 'CO__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND',
                    token: '__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__'
                }
            }),
            legend: 'https://geoservices.meteofrance.fr/api/__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS?service=WMS&version=1.3.0&sld_version=1.1.0&request=GetLegendGraphic&layer=CO__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND&style=CO_USI__HEIGHT__SHADING&format=image/png',
            opacity: 0.7
        });
        let o3 = new TileLayer({
            id: "o3",
            title: "O3",
            baseLayer: true,
            visible: false,
            source: new TileWMS({
                url: 'https://geoservices.regional.atmosphere.copernicus.eu/services/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS',
                params: {
                    LAYERS: 'O3__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND',
                    token: '__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__'
                }
            }),
            legend: 'https://geoservices.meteofrance.fr/api/__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS?service=WMS&version=1.3.0&sld_version=1.1.0&request=GetLegendGraphic&layer=O3__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND&style=O3_USI__HEIGHT__SHADING&format=image/png',
            opacity: 0.7
        });
        let so2 = new TileLayer({
            id: "so2",
            title: "SO2",
            baseLayer: true,
            visible: false,
            source: new TileWMS({
                url: 'https://geoservices.regional.atmosphere.copernicus.eu/services/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS',
                params: {
                    LAYERS: 'SO2__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND',
                    token: '__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__'
                }
            }),
            legend: 'https://geoservices.meteofrance.fr/api/__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS?service=WMS&version=1.3.0&sld_version=1.1.0&request=GetLegendGraphic&layer=SO2__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND&style=SO2_USI__HEIGHT__SHADING&format=image/png',
            opacity: 0.7
        });
        let nh3 = new TileLayer({
            id: "nh3",
            title: "NH3",
            baseLayer: true,
            visible: false,
            source: new TileWMS({
                url: 'https://geoservices.regional.atmosphere.copernicus.eu/services/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS',
                params: {
                    LAYERS: 'NH3__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND',
                    token: '__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__'
                }
            }),
            legend: 'https://geoservices.meteofrance.fr/api/__HiA8p4yE8hjBHmaXMSW3A3L0nXqtxeB_A-buTYQ2EVw__/CAMS50-ENSEMBLE-FORECAST-01-EUROPE-WMS?service=WMS&version=1.3.0&sld_version=1.1.0&request=GetLegendGraphic&layer=NH3__SPECIFIC_HEIGHT_LEVEL_ABOVE_GROUND&style=SO2_USI__HEIGHT__SHADING&format=image/png',
            opacity: 0.7
        });

        let map_view = new View({
            projection: 'EPSG:4326',
            center: [23.0373367, 40.5027826],
            zoom: 12,
            minZoom: 10,
            extent: [22.5740656, 40.3054957, 23.4378656, 40.6814637]
        });
        let map = new Map({
            layers: [
                osmLayer,
                pm25,
                pm10,
                no,
                no2,
                co,
                o3,
                so2,
                nh3

            ],
            controls: defaultControls({attribution: false}).extend([
                new LayerPopup(),
                LegendControl
            ]),
            target: 'cams-map',
            view: map_view
        });
        LegendControl.set(map);

        this.setState({
            map: map
        });
    }

    render() {

        return (
            <>
                <div id="cams-map" className="map"/>
                <TimeSlider
                    min={this.state.min}
                    max={this.state.max}
                    step={this.state.step}
                    map={this.state.map}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
});

Cams = connect(mapStateToProps)(Cams);

export default Cams;
