import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Container,
    Row,
    Col,
    CardGroup,
    Card,
    CardBody,
    Button,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import T from 'modules/i18n';

import Dygraph from 'dygraphs';
import Temp from './tmp/temperatures.csv';

import './css/thermiair.css';
import {requestData} from "core/ducks/list";
import Loading from "core/components/loading";


class Pollutionvariation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pollutants: [
                {
                    type: 'PM2.5',
                    charts: ['month'],
                    avg10min: null,
                    avg30min: null,
                    avg1hr: null,
                    avg6hr: null,
                    avgDay: null,
                    avgWeek: null
                },
                {
                    type: 'PM10',
                    charts: ['month'],
                    avg10min: null,
                    avg30min: null,
                    avg1hr: null,
                    avg6hr: null,
                    avgDay: null,
                    avgWeek: null
                },
                {
                    type: 'PM1.0',
                    charts: ['month'],
                    avg10min: null,
                    avg30min: null,
                    avg1hr: null,
                    avg6hr: null,
                    avgDay: null,
                    avgWeek: null
                }
            ],
            refreshing: true,
            data: null,
            activeStation: null,
            activeStationLabel: 'thermi - dimarchio'
        };
        this.chooseStationHandle = this.chooseStationHandle.bind(this);
        this.createChart = this.createChart.bind(this);
        this.createAvg = this.createAvg.bind(this);
    }

    componentDidMount() {
        console.log(this.props.location.data);

        if (this.props.location.data) {
            this.setState({
                activeStationLabel: this.props.location.data.label
            });
        }

        this.setStationsData();
    }

    setStationsData() {
        this.setState({refreshing: true});
        this.props.dispatch(requestData('dashboardData', 'data/bbox/180,180,-180,-180,4326')).then(() => {
            this.setState({refreshing: false, data: this.props.dashboardDataList},function () {
                this.setData();
            });
        });
    }

    setColor(value) {
        let s = null;
        if (value >= 0 && value < 10) {
            s = {background: '#5ed5ff', padding: '10px', margin: '20px'}
        } else if (value >= 10 && value < 20) {
            s = {background: '#92d14f', padding: '10px', margin: '20px'}
        } else if (value >= 20 && value < 25) {
            s = {background: '#ffff00', padding: '10px', margin: '20px'}
        } else if (value >= 25 && value < 50) {
            s = {background: '#fc3903', color: 'white', padding: '10px', margin: '20px'}
        } else if (value >= 50) {
            s = {background: '#990100', color: 'white', padding: '10px', margin: '20px'}
        }
        return s
    }

    chooseStationHandle(e) {
        let station = null;
        this.state.data.map((item) => {
            if (item.label === e.target.value) {
                station = item;
            }
        });
        this.setState({
            activeStation: station,
            activeStationLabel: station.label
        }, function () {
            this.setData();
        });
    }

    setData() {
        let t = this;
        let tokenPM25 = null;
        let tokenPM10 = null;
        let tokenPM1 = null;
        this.state.data.map((item) => {
            if (item.label === this.state.activeStationLabel) {
                item.datastreams.map((obj) => {
                    if (obj.label === 'Two-channel PM2.5') {
                        tokenPM25 = obj.token;
                    } else if (obj.label === 'Two-channel PM10') {
                        tokenPM10 = obj.token;
                    } else if (obj.label === 'Two-channel PM1.0') {
                        tokenPM1 = obj.token;
                    }
                });
            }
        });

        this.props.dispatch(requestData('stationPM25AvgData', 'data/token/' + tokenPM25)).then(() => {
            this.createAvg(0, 'PM2.5', this.props.stationPM25AvgDataList)
        });
        this.props.dispatch(requestData('stationPM25MonthData', 'data/token/' + tokenPM25 + '/timeseries/1%20month')).then(() => {
            this.createChart(0, this.props.stationPM25MonthDataList.series);
        });


        this.props.dispatch(requestData('stationPM10AvgData', 'data/token/' + tokenPM10)).then(() => {
            this.createAvg(1, 'PM10', this.props.stationPM10AvgDataList)
        });
        this.props.dispatch(requestData('stationPM10MonthData', 'data/token/' + tokenPM10 + '/timeseries/1%20month')).then(() => {
            this.createChart(1, this.props.stationPM10MonthDataList.series);
        });


        this.props.dispatch(requestData('stationPM1AvgData', 'data/token/' + tokenPM1)).then(() => {
            this.createAvg(2, 'PM1.0', this.props.stationPM1AvgDataList)
        });
        this.props.dispatch(requestData('stationPM1MonthData', 'data/token/' + tokenPM1 + '/timeseries/1%20month')).then(() => {
            this.createChart(2, this.props.stationPM1MonthDataList.series);
        });

    }

    createAvg(idx, type, data){
        const array = [...this.state.pollutants];
        array[idx] = {
            type: type,
            charts: ['month'],
            avg10min: data['10 minutes'].toFixed(2),
            avg30min: data['30 minutes'].toFixed(2),
            avg1hr: data['1 hour'].toFixed(2),
            avg6hr: data['6 hours'].toFixed(2),
            avgDay: data['24 hours'].toFixed(2),
            avgWeek: data['1 week'].toFixed(2)
        };
        this.setState({pollutants: array});
    }

    createChart(idx, data){
        let t = this;
        let series = [];
        let s = data;
        s.map(function (item) {
            series.push([new Date(item.result_time), parseFloat(item.value)])
        });
        series.sort(function (a, b) {
            return a[0] - b[0];
        });
        new Dygraph(this.state.pollutants[idx].type + "-" + this.state.pollutants[idx].charts[0],
            series, {
                labels: ['Date', t.props.i18n['thermiairValue']],
                title: t.props.i18n['thermiairVariation'],
                fillGraph: true,
                showRangeSelector: true
            });
    }

    render() {

        return (
            <div className="app-container flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={12}>
                            <CardGroup>
                                <Card>
                                    <CardBody className="text-center">
                                        <div>
                                            <Row className="justify-content-center">
                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label for="stationSelect" md={4}
                                                               size="lg"><T>thermiairSensor</T></Label>
                                                        <Col md={8}>
                                                            <Input type="select" name="select" id="stationSelect"
                                                                   value={this.state.activeStationLabel}
                                                                   onChange={this.chooseStationHandle}>
                                                                {
                                                                    !this.state.refreshing ?
                                                                        this.state.data.map((item) =>
                                                                            <option
                                                                                key={item.label}
                                                                                value={item.label}>{item.description}</option>
                                                                        )
                                                                        : <Loading/>
                                                                }
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {
                                                this.state.pollutants.map((item) =>
                                                    <div>
                                                        <hr/>
                                                        <Row className="justify-content-center" style={{
                                                            height: '50px',
                                                            background: 'aliceblue',
                                                            margin: '0 0.1em'
                                                        }}>
                                                            <Col md={12}>
                                                                <h5>{item.type}</h5>
                                                            </Col>
                                                        </Row>
                                                        <Row className="justify-content-center">
                                                            <Col md={6}>
                                                                <Row
                                                                    className="justify-content-center">{this.props.i18n['thermiairAvg']}</Row>
                                                                <Row className="justify-content-center">
                                                                    <Col md={4}>
                                                                        <div className="pollution-variation-div"
                                                                             style={this.setColor(item.avg10min)}>
                                                                            <span>
                                                                                <div>10 {this.props.i18n['thermiairMin']}</div>
                                                                            </span>{item.avg10min} μg/m³
                                                                        </div>
                                                                        <div className="pollution-variation-div"
                                                                             style={this.setColor(item.avg30min)}>
                                                                            <span>
                                                                                <div>30 {this.props.i18n['thermiairMin']}</div>
                                                                            </span>{item.avg30min} μg/m³
                                                                        </div>
                                                                        <div className="pollution-variation-div"
                                                                             style={this.setColor(item.avg1hr)}>
                                                                            <span>
                                                                                <div>1 {this.props.i18n['thermiairHour']}</div>
                                                                            </span>{item.avg1hr} μg/m³
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <div className="pollution-variation-div"
                                                                             style={this.setColor(item.avg6hr)}>
                                                                            <span>
                                                                                <div>6 {this.props.i18n['thermiairHours']}</div>
                                                                            </span>{item.avg6hr} μg/m³
                                                                        </div>
                                                                        <div className="pollution-variation-div"
                                                                             style={this.setColor(item.avgDay)}>
                                                                            <span>
                                                                                <div>1 {this.props.i18n['thermiairDay']}</div>
                                                                            </span>{item.avgDay} μg/m³
                                                                        </div>
                                                                        <div className="pollution-variation-div"
                                                                             style={this.setColor(item.avgWeek)}>
                                                                            <span>
                                                                                <div>{this.props.i18n['thermiairWeek']}</div>
                                                                            </span>{item.avgWeek} μg/m³
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div id={item.type + "-" + item.charts[0]}
                                                                     className="dygraph-div"/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    i18n: state.i18n.messages,
    notifications: state.notifications.messages,
    dashboardDataList: state.list.dashboardData.data,
    stationPM1AvgDataList: state.list.stationPM1AvgData.data,
    waitStationPM1AvgData: state.list.stationPM1AvgData.pending,
    stationPM25AvgDataList: state.list.stationPM25AvgData.data,
    waitStationPM25AvgData: state.list.stationPM25AvgData.pending,
    stationPM10AvgDataList: state.list.stationPM10AvgData.data,
    waitStationPM10AvgData: state.list.stationPM10AvgData.pending,


    stationPM1MonthDataList: state.list.stationPM1MonthData.data,
    waitStationPM1MonthData: state.list.stationPM1MonthData.pending,
    stationPM25MonthDataList: state.list.stationPM25MonthData.data,
    waitStationPM25MonthData: state.list.stationPM25MonthData.pending,
    stationPM10MonthDataList: state.list.stationPM10MonthData.data,
    waitStationPM10MonthData: state.list.stationPM10MonthData.pending
});

Pollutionvariation = connect(mapStateToProps)(Pollutionvariation);

export default Pollutionvariation;
