import React, {Component} from 'react';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './timeslider.css'
import moment from 'moment';


class TimeSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: null,
            value: this.props.min + 1080000
        };
    }

    onSliderChange = (value) => {
        this.setState({
            value,
        });
    };

    play = () => {

        let t = this;

        let play = document.getElementById("ol-custom-timeslider-play").classList;
        play.toggle("active");

        if (play.contains("active")) {

            const min = t.props.min;
            const max = t.props.max;
            const step = t.props.step;

            let timer = setInterval(function () {
                let v = t.state.value;
                t.setState({
                    value: v + step
                });
                if(t.state.value >= max + step){
                    t.setState({
                        value: min
                    });
                    // clearInterval(t.state.timer);
                    // play.toggle("active");
                }
            }, 1500);
            t.setState({
                timer: timer
            })
        }else{
            clearInterval(t.state.timer);
        }

    };

    forward = () => {
        let v = this.state.value;
        const min = this.props.min;
        const max = this.props.max;
        const step = this.props.step;
        if(this.state.value >= max){
            this.setState({
                value: min
            });
        }else{
            this.setState({
                value: v + step
            });
        }
    };

    backward = () => {
        let v = this.state.value;
        const min = this.props.min;
        const max = this.props.max;
        const step = this.props.step;
        if(this.state.value <= min){
            this.setState({
                value: min
            });
        }else{
            this.setState({
                value: v - step
            });
        }
    };

    render() {
        let t = this;
        const marks = {};
        let dd = moment(this.props.min).format('DD').toString();
        for (let i = this.props.min; i <= this.props.max; i += this.props.step) {
            let changeDate = false;
            let utcOffset = moment().utcOffset();
            try {
                if (dd !== moment(i).format('DD').toString()) {
                    changeDate = true;
                    dd = moment(i).format('DD').toString()
                }
            } catch (e) {

            }

            if (changeDate) {
                // marks[i - (utcOffset * 3600000 / 60)] = {
                //     style: {
                //         transform: 'translateX(-50%) rotate(45deg)',
                //         transformOrigin: '20px 45px',
                //         color: 'red'
                //     },
                //     label: moment(i - (utcOffset * 3600000 / 60)).format('DD/MM/YYYY').toString()
                // };

                marks[i] = {
                    style: {
                        transform: 'translateX(0%) rotate(45deg)',
                        transformOrigin: '0px 0px',
                        color: 'red',
                        fontWeight: 600
                    },
                    label: moment(i).format('DD/MM/YYYY').toString()
                }
            } else {
                marks[i] = {
                    style: {
                        transform: 'translateX(0%) rotate(45deg)',
                        transformOrigin: '0px 0px'
                    },
                    label: moment(i).format('HH:mm').toString()
                }
            }
        }
        const Handle = Slider.Handle;
        const handle = (props) => {
            const {value, dragging, index, ...restProps} = props;

            try {
                document.getElementById("ol-custom-timeslider-timestamp").innerHTML = moment(value).format('DD/MM/YYYY - HH:mm');
                t.props.map.getLayers().getArray().forEach(function (item) {
                    try {
                        item.getSource().updateParams({'TIME': moment(value).toISOString()});
                    } catch (e) {
                    }
                });
            } catch (e) {
            }

            return (
                <Tooltip
                    prefixCls="rc-slider-tooltip"
                    overlay={moment(value).format('DD/MM/YYYY - HH:mm')}
                    visible={true}
                    placement="top"
                    key={index}
                >
                    <Handle value={value} {...restProps} />
                </Tooltip>
            );
        };

        return (
            <div className="ol-custom-timeslider-div">
                <a id="ol-custom-timeslider-play" title="play" className="play" onClick={this.play}/>
                <div id="ol-custom-timeslider-timestamp">simos</div>
                <div className="ol-custom-timeslider-slider">
                    <Slider
                        min={this.props.min}
                        max={this.props.max}
                        marks={marks}
                        value={this.state.value}
                        step={this.props.step}
                        included={false}
                        handle={handle}
                        dots={true}
                        onChange={this.onSliderChange}
                    />
                </div>
                <a id="ol-custom-timeslider-forward" title="forward" className="forward" onClick={this.forward}/>
                <a id="ol-custom-timeslider-backward" title="backward" className="backward" onClick={this.backward}/>
            </div>);
    }
}

export default TimeSlider;
