import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardText,
	CardFooter,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	InputGroupAddon,
	InputGroup,
	UncontrolledTooltip,
	Form,
} from 'reactstrap';

import { ErrorPages } from 'core/model/routes';
import { Loading } from 'core/components';
import { requestData } from 'core/ducks/list';
import { postData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import * as roles from 'core/model/roles';
import { DynamicRoutes } from 'core/model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import T from 'modules/i18n';

class CreateWorkflow extends Component {

	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			values: {name: ''}
		};

		this.handleChange = this.handleChange.bind(this);
		this.apply = this.apply.bind(this);
		this.toggle = this.toggle.bind(this);
	}

	handleChange(event) {
		this.setState({
			values: {
				...this.state.values,
				[event.target.name]: event.target.value
			}
		});
	}

	apply(event) {
		const { dispatch } = this.props;
		const { mname } = this.props.match.params;
		event.preventDefault();
		if (this.state.modalOpen)
			this.toggle();
		const data = {'workflow': mname, 'name': this.state.values.name, 'workflow_revision': 1};
		dispatch( postData('application', data, false) );
	}

	toggle() {
		this.setState( {modalOpen: !this.state.modalOpen} );
	}

	componentDidMount() {
		const { dispatch } = this.props;
		const { mname } = this.props.match.params;
		dispatch(requestData('workflow', `workflow/mname/${mname}/revision/1`));
	}

	componentDidUpdate(prevProps) {
		if ( prevProps.list.sending && !this.props.list.sending ) {
			if (typeof this.props.list.data.mname == 'undefined')
				this.props.history.push(ErrorPages.NotFound);
		}

		if ( prevProps.update.sending && !this.props.update.sending ) {
			if (this.props.update.status === 200) {
				this.props.dispatch( pushNotification({body: 'application created', type: 'success'}) );
				if (JSON.stringify(prevProps.update.message) !== JSON.stringify(this.props.update.message)) {
					const { uuid, node } = this.props.update.message;
					let path = buildPath(DynamicRoutes.Apply, [uuid, node]);
					this.props.history.push(path);
				}
			} else {
				this.props.dispatch( pushNotification({body: 'action denied', type: 'warning'}) );
			}
		}
	}

	render() {
		const { list, role } = this.props;
		if (list.pending)
			return (<Loading />);

		let workflow = list.data;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<Row>
				<Col xs="12">
					<Card>
						<CardHeader>
							<h4>{workflow.name}</h4>
						</CardHeader>
						<CardBody>
							<CardText>
								{workflow.description}
							</CardText>
						</CardBody>
						<CardFooter>
							<Button type="button" onClick={role===roles.GUEST ? this.toggle : this.apply}>
								<T>apply</T>
							</Button>
						</CardFooter>
					</Card>
				</Col>
				<Modal isOpen={this.state.modalOpen} className="modal-md">
					<ModalHeader toggle={this.toggle}><T>initiate application</T></ModalHeader>
					<ModalBody>
						<Form onSubmit={this.apply}>
							<InputGroup>
								<input
									autoFocus
									className="form-control"
									type="text"
									placeholder={messages.friendly_name || 'friendly_name'}
									maxLength="255"
									name="name"
									value={this.state.values.name}
									onChange={this.handleChange}
								/>
								<InputGroupAddon addonType="append">
									<Button type="submit">
										Submit
									</Button>
								</InputGroupAddon>
								<i
									id="friendly_name_help"
									className="fa fa-question-circle fa-lg mt-2 ml-3"
								/>
							</InputGroup>
						</Form>
						<UncontrolledTooltip placement="right" target="friendly_name_help">
							<T>enter a friendly name for your own convenience</T>.
						</UncontrolledTooltip>
					</ModalBody>
				</Modal>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list.workflow,
	update: state.update,
	role: state.profile.user.role,
	i18n: state.i18n,
});

CreateWorkflow = connect(mapStateToProps)(CreateWorkflow);

export default CreateWorkflow;
