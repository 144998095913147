const Dashboard = '/dashboard';
const PollutionMap = '/pollutionmap';
const PollutionVariation = '/pollutionvariation';
const Statistics = '/statistics';
const Cams = '/cams';

export const StaticRoutes = {
	Dashboard, PollutionMap, PollutionVariation, Statistics, Cams
};

export const routes = {
	[Dashboard]: {
		name: 'dashboard',
		component: 'Dashboard'
	},
	[PollutionMap]: {
		name: 'pollutionmap',
		component: 'PollutionMap'
	},
	[PollutionVariation]: {
		name: 'pollutionvariation',
		component: 'PollutionVariation'
	},
	[Statistics]: {
		name: 'statistics',
		component: 'Statistics'
	},
	[Cams]: {
		name: 'cams',
		component: 'Cams'
	}
};

const combined = {StaticRoutes, routes};

export default combined;
