import React, {Component} from "react";
import * as dc from "dc";
import {scaleBand, scaleLinear, scaleOrdinal} from "d3";
import {ChartTemplate} from "./chartTemplate";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";

class StationChart10 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            chart: null
        };
        this.stationChartFunc = this.stationChartFunc.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pollutant !== this.props.pollutant) {



            dc.renderAll();
        }
    }

    stationChartFunc(divRef, ndx) {
        let pollutant = 'PM10';
        let stationChart = dc.barChart(divRef);
        let stationDimension = ndx.dimension(function (d) {
            return d.Station;
        });
        let avgGroupDimension = stationDimension.group().reduce(
            function (p, v) {
                ++p.count;
                p.total += v[pollutant];
                return p;
            },
            function (p, v) {
                --p.count;
                p.total -= v[pollutant];
                return p;
            },
            function () {
                return {count: 0, total: 0};
            }
        );
        stationChart
            .x(scaleBand())
            .y(scaleLinear().domain([0, 80]))
            .xUnits(dc.units.ordinal)
            .brushOn(false)
            .xAxisLabel(' ')
            .yAxisLabel('μg/m³')
            .dimension(stationDimension)
            .barPadding(0.1)
            .outerPadding(0.05)
            .group(avgGroupDimension)
            .valueAccessor(function (p) {
                return p.value.count > 0 ? Math.round(p.value.total / p.value.count * 100) / 100 : 0;
            })
            .colorAccessor(function (p) {
                let tmp = p.value.count > 0 ? Math.round(p.value.total / p.value.count * 100) / 100 : 0;
                if (tmp < 10) {
                    return "class1";
                } else if (tmp >= 10 && tmp < 20) {
                    return "class2";
                } else if (tmp >= 20 && tmp < 25) {
                    return "class3";
                } else if (tmp >= 25 && tmp < 50) {
                    return "class4";
                } else if (tmp >50) {
                    return "class5";
                }
            })
            .colors(scaleOrdinal().domain(["class1", "class2", "class3", "class4", "class5"])
                .range(["#5ED5FF", "#92D14F", "#FFFF00", "#FC3903", "#990100"]));

        stationChart.renderlet(function (chart) {
            // rotate x-axis labels
            chart.selectAll('g.x text')
                .attr('transform', 'translate(-10,10) rotate(315)');
        });

        this.setState({
            ndx: ndx,
            stationDimension: stationDimension,
            avgGroupDimension: avgGroupDimension,
            pollutant: pollutant,
            chart: stationChart
        });

        return stationChart
    }

    render() {
        return (
            <ChartTemplate chartFunction={this.stationChartFunc} resetFunction={true}
                               title={this.props.i18n['thermiairAvgStation'] }/>
        )
    }

}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n.messages
});

StationChart10 = connect(mapStateToProps)(injectIntl(StationChart10));

export default StationChart10;
