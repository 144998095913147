import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Form, FormGroup, Label, Button, FormFeedback,
	InputGroup, InputGroupAddon, InputGroupText, FormText
} from 'reactstrap';

import { Input } from 'core/components';
import { OlMap as Map } from 'input-fields';
import { File } from 'file-upload';
import T, { getLocale } from 'modules/i18n';
import { getValidation, validate } from 'core/ducks/forms';
import { Loading } from 'core/components';

class DefineTypology extends Component {

	constructor(props) {
		super(props);
		const initialValues = {
			name: '',
			description: '',
            features: [],
			properties: [],
			image: '',
			pdf: ''
		};
		this.state = {
			values: {...initialValues},
			properties_number: 0,
			metadataExist: false,
			underSubmit: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.changePropertiesNumber = this.changePropertiesNumber.bind(this);
		this.toggleMetadata = this.toggleMetadata.bind(this);
	}

	handleChange(event) {
		let target = event.target;
		this.setState({
			values: {
				...this.state.values,
				[target.name]: target.value
			},
			underSubmit: false
		});
	}

	changePropertiesNumber(event) {
		const { value } = event.target;
		let properties;
		const length = this.state.values.properties.length;
		if (value < length) {
			properties = this.state.values.properties.filter((property, index) => index < value);
		} else {
			properties = [...this.state.values.properties, ...Array(value - length).fill({ type: '', options: '' })];
		}
		this.setState({
			properties_number: value,
			values: {
				...this.state.values,
				properties
			}
		});
	}

	toggleMetadata(event) {
		this.setState({metadataExist: event.target.checked});
	}

	componentDidMount() {
		const { dispatch, rules } = this.props;
		dispatch(getValidation('variable'));
	}

	render() {

		const { rules, validation_msgs } = this.props;

		if (Object.keys(this.props.rules).length === 0)
			return (<Loading />);

		const locale = this.props.dispatch(getLocale());

		return (
			<Form className="mb-5">
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor="name_input" className="application-label"><T>name</T>*</Label>
							<Input
								id="name_input"
								type="text"
								name="name"
								value={this.state.values.name}
								required
								autoComplete="off"
								pattern={rules.name.validation}
								maxLength={rules.name.max_size || undefined}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor="description_input" className="application-label"><T>description</T></Label>
							<Input
								id="description_input"
								type="textarea"
								name="description"
								value={this.state.values.description}
								required
								autoComplete="off"
								pattern={rules.description.validation}
								maxLength={rules.description.max_size || undefined}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor="location_input" className="application-label"><T>location</T></Label>
							<Map
								id="location_input"
                               value={this.state.values.features}
								name="features"
								options={{"position":[21.608216,40.3284628],"zoom":7,"EPSG":"EPSG:4326"}}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup tag="fieldset">
							<Label className="application-label">
								<T>properties</T>
								<input
									id="properties_number"
									type="number"
									min="0"
									max="10"
									className="mx-3 px-1"
									value={this.state.properties_number}
									onChange={this.changePropertiesNumber}
								/>
							</Label>
							<Row>
								{this.state.values.properties.map((property, index) => (
									<Col xs="12" sm="6" md="4" xl="3" className="border-bottom border-right" key={`col_${index}`}>
										<FormGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<Label htmlFor="type_input" className="p-0 m-0"><T>type</T></Label>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													id="type_input"
													type="select"
													name="type"
													required
													autoComplete="off"
												>
													<option value="">...επιλέξτε τύπο...</option>
													<option>radio</option>
													<option>select</option>
													<option>text</option>
													<option>multichoice</option>
													<option>file</option>
												</Input>
											</InputGroup>
										</FormGroup>
										<FormGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText><T>options</T></InputGroupText>
												</InputGroupAddon>
												<Input
													type="text"
													name="options"
													value={property.options}
													autoComplete="off"
													onChange={this.handleChange}
												/>
											</InputGroup>
										</FormGroup>
									</Col>
								))}
							</Row>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup tag="fieldset">
							<Label htmlFor="metadata_checkbox" className="application-label">
								<T>documentation files</T>
								<input
									id="metadata_checkbox"
									type="checkbox"
									className="mx-3 px-1"
									checked={this.state.metadataExist}
									onChange={this.toggleMetadata}
								/>
							</Label>
							<FormText>[Placeholder] Περιγραφή αρχείων τεκμηρίωσης</FormText>
							{ this.state.metadataExist &&
								<ul className="list-group mt-2">
									<li className="list-group-item">
										<FormGroup>
											<Label className="application-label"><T>image</T></Label>
											<File
												name="image"
												value={this.state.values.image}
												locale={locale}
												onChange={this.handleChange}
												filetype={["image/*"]}
												maxFileSize="8Mb"
											/>
										</FormGroup>
									</li>
									<li className="list-group-item">
										<FormGroup>
											<Label className="application-label"><T>link</T></Label>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<Label htmlFor="link_input" className="p-0 m-0">URI</Label>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													id="link_input"
													type="text"
													name="name"
													value={this.state.values.link}
													autoComplete="off"
													onChange={this.handleChange}
													placeholder="http://"
												/>
											</InputGroup>
										</FormGroup>
									</li>
									<li className="list-group-item">
										<FormGroup>
											<Label className="application-label"><T>pdf file</T></Label>
											<File
												name="pdf"
												value={this.state.values.pdf}
												locale={locale}
												onChange={this.handleChange}
												filetype={["application/pdf"]}
												maxFileSize="8Mb"
											/>
										</FormGroup>
									</li>
								</ul>
							}
						</FormGroup>
					</Col>
				</Row>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	rules: state.forms.validation.rules,
	valid: state.forms.valid,
	validation_msgs: state.forms.validation_msgs,
});

DefineTypology = connect(mapStateToProps)(DefineTypology);

export default DefineTypology;
