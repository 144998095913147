import React, {Component} from "react";
import {Button, Form, FormGroup, Label, Input, FormText, Row, Col} from 'reactstrap';
import {CXContext} from "./cxContext";
import * as dc from "dc";
import {scaleBand, scaleLinear} from "d3";
import {ChartTemplate} from "./chartTemplate";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import moment from 'moment';
import airqualityindeximg from 'core/views/layout/img/airqualityindex.jpg';


class Options extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pollutant: this.props.pollutant,
            from: null,
            to: moment().format("YYYY-MM-DD")
        };
        this.setOptionDateFromFunc = this.setOptionDateFromFunc.bind(this);
        this.setOptionDateToFunc = this.setOptionDateToFunc.bind(this);
    }

    setOptionStationFunc(divRef, ndx) {
        let stationSelection = dc.selectMenu(divRef);

        let stationDimension = ndx.dimension(function (d) {
            return d.Station;
        });

        stationSelection
            .dimension(stationDimension)
            .group(stationDimension.group())
            .controlsUseVisibility(true);

        return stationSelection
    }

    setOptionDateFromFunc(divRef, ndx) {

        let t = this;

        let dateSelection = dc.selectMenu(divRef);

        let dateDimension = ndx.dimension(function (d) {
            return d.humanDate;
        });

        dateSelection
            .dimension(dateDimension)
            .group(dateDimension.group())
            .controlsUseVisibility(true);

        dateSelection.filterHandler(function (dimension, filters) {
            t.setState({
                from: filters[0]
            });
            dimension.filterRange([filters[0], t.state.to]);
        });

        return dateSelection
    }

    setOptionDateToFunc(divRef, ndx) {
        let t = this;

        let dateSelection = dc.selectMenu(divRef);

        let dateDimension = ndx.dimension(function (d) {
            return d.humanDate;
        });

        dateSelection
            .dimension(dateDimension)
            .group(dateDimension.group())
            .controlsUseVisibility(true);

        dateSelection.filterHandler(function (dimension, filters) {
            if (filters.length > 0) {
                t.setState({
                    to: filters[0]
                });
                dimension.filterRange([t.state.from, filters[0]]);
            } else {
                t.setState({
                    to: moment().format("YYYY-MM-DD")
                });
                dimension.filterRange([t.state.from, moment().format("YYYY-MM-DD")]);
            }
        });

        return dateSelection
    }

    render() {
        return (
            <Row style={{marginTop: '-3em', marginBottom: '2em'}}>
                <Col/>
                <Col md={3}>
                    <ChartTemplate chartFunction={this.setOptionStationFunc} resetFunction={true}
                                   title={this.props.i18n['thermiairSensor']}/>
                </Col>
                <Col md={3}>
                    <ChartTemplate chartFunction={this.setOptionDateFromFunc} resetFunction={true}
                                   title={this.props.i18n['thermiairFrom']}/>
                </Col>
                <Col md={3}>
                    <ChartTemplate chartFunction={this.setOptionDateToFunc} resetFunction={true}
                                   title={this.props.i18n['thermiairTo']}/>
                </Col>
                <Col />
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n.messages
});

Options = connect(mapStateToProps)(injectIntl(Options));

export default Options;

