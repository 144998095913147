import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, FormGroup, Label, Button, Nav, NavItem, NavLink, TabContent, TabPane, Table } from 'reactstrap';
import { FormattedDate } from 'react-intl';

import { applicationStatus } from 'core/model/constants';
import { setAsideState, clearAsideState } from 'core/ducks/ui/menu';
import { requestData } from 'core/ducks/list';
import { FileTab } from 'flows-app/views/sidebars';
import T from 'modules/i18n';

class PPcityMap extends Component {

	constructor(props) {
		super(props);

		this.initialState = {
			query: '',
			category: '',
			status: ''
		}

		this.state = {
			pending: false,
			proc: '',
			activeTab: '1',
			...this.initialState
		};

		props.dispatch(
			setAsideState(this.initialState)
		);

		this.toggle = this.toggle.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.reset = this.reset.bind(this);
	}

	toggle(tab) {
		if (this.state.activeTab !== tab)
			this.setState({activeTab: tab});
	}

	handleInputChange(event) {
		const state = {[event.target.name]: event.target.value};
		const { pending } = this.state;
		this.setState(state);
		if (pending)
			clearTimeout(this.state.proc);
		let proc = setTimeout(() => {
			this.props.dispatch(
				setAsideState(state)
			);
			this.setState({pending: false, proc: ''});
		}, 500);
		this.setState({
			pending: true,
			proc
		});
	}

	reset() {
		this.setState(this.initialState);
		this.props.dispatch(setAsideState(this.initialState));
	}

	componentDidMount() {
		this.props.dispatch( requestData('workflow', 'workflow/hidden/false/type/public/') );
	}

	componentWillUnmount() {
		this.props.dispatch(clearAsideState());
	}

	render() {
		const { i18n, details, waitWorkflows, workflowList } = this.props;

		if (waitWorkflows || !workflowList)
			return null;

		return (
			<React.Fragment>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('0'); }}
						>
							<T>details</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='1' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('1'); }}
						>
							<T>filters</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='2' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('2'); }}
						>
							<T>files</T>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
					<TabPane tabId="0">
						{ (!this.props.pending && details.uuid) ?
							<Table>
								<thead>
									<tr>
										<th colSpan={2} className="text-center">{details.workflow}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th className="px-0 small"><T>registry_number</T></th>
										<td>{details.registry_number}</td>
									</tr>
									<tr>
										<th className="px-0 small"><T>arithmos_fakelou</T></th>
										<td>{details.arithmos_fakelou}</td>
									</tr>
									<tr>
										<th className="px-0 small"><T>defteros_arithmos_fakelou</T></th>
										<td>{details.defteros_arithmos_fakelou}</td>
									</tr>
									<tr>
										<th className="px-0 small"><T>author</T></th>
										<td>{details.author}</td>
									</tr>
									<tr>
										<th className="px-0 small"><T>created</T></th>
										<td>
											<FormattedDate
												value={details.created}
												year='numeric'
												month='short'
												day='2-digit'
											/>
										</td>
									</tr>
									<tr>
										<th className="px-0 small"><T>revision</T></th>
										<td>{details.revision}</td>
									</tr>
									<tr>
										<th className="px-0 small"><T>step</T></th>
										<td>{details.step}</td>
									</tr>
									<tr>
										<th className="px-0 small"><T>reviewer</T></th>
										<td>{details.reviewer}</td>
									</tr>
									<tr>
										<th className="px-0 small"><T>status</T></th>
										<td><T>{details.status}</T></td>
									</tr>
								</tbody>
							</Table>
							:
							<p><T>select a point to see details</T></p>
						}
					</TabPane>
					<TabPane tabId="1">
						<Row>
							<Col>
								<FormGroup>
									<Label htmlFor="query"><T>search</T></Label>
									<Input
										id="query"
										placeholder={i18n.search}
										value={this.state.query}
										name="query"
										onChange={this.handleInputChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label htmlFor="workflows_list"><T>category</T></Label>
									<Input
										id="workflows_list"
										type="select"
										value={this.state.category}
										name="category"
										onChange={this.handleInputChange}
									>
										<option value="">{`${i18n.choose} ${i18n.category}`}</option>
										{ Object.keys(workflowList).map((key) =>
											<option key={`workflow_${key}`} value={key}>{workflowList[key].name}</option>
										)}
									</Input>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label htmlFor="status"><T>status</T></Label>
									<Input
										id="status"
										type="select"
										value={this.state.status}
										name="status"
										onChange={this.handleInputChange}
									>
										<option value="">{`${i18n.choose} ${i18n.status}`}</option>
										{ applicationStatus.map((status) =>
											<option key={`option_${status}`} value={status}>{i18n[status]}</option>
										) }
									</Input>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button type="button" onClick={this.reset}><T>reset</T></Button>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId="2">
						{ (!this.props.pending && details.uuid)
							? <FileTab uuid={details.uuid} />
							: <p><T>select a point to see details</T></p>
						}
					</TabPane>
				</TabContent>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n.messages,
	asideState: state.ui.menu.asideState,
	details: state.update.response,
	pending: state.update.pending,
	workflowList: state.list.workflow.data,
	waitWorkflows: state.list.workflow.pending,
});

PPcityMap = connect(mapStateToProps)(PPcityMap);

export default PPcityMap;
