/**
 * Static routes
 */

// Admin
const PPcityMap = '/map';

export const StaticRoutes = {
	PPcityMap,
};

export const routes = {
	[PPcityMap]: {
		name: 'map',
		description: 'The map',
		title: 'links.ppCity.map',
		defaultTitle: 'ppCity map',
		component: 'PPcityMap',
		contextComponent: 'PPcityMap',
	},
};

const combined = { StaticRoutes, routes };

export default combined;
