import React, {Component} from "react";
import * as dc from "dc";
import {scaleBand, scaleLinear, scaleOrdinal} from "d3";
import {ChartTemplate} from "./chartTemplate";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";


class HourChart10 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pollutant: null
        };
        this.hourChartFunc = this.hourChartFunc.bind(this);
    }

    hourChartFunc(divRef, ndx){
        let pollutant = 'PM10';
        const hourChart = dc.barChart(divRef)
        const stationDimension = ndx.dimension(function(d) {
            return d.hour;
        });
        const avgGroupPM25 = stationDimension.group().reduce(
            function (p, v) {
                ++p.count;
                p.total += v[pollutant];
                return p;
            },
            function (p, v) {
                --p.count;
                p.total -= v[pollutant];
                return p;
            },
            function () {
                return {count: 0, total: 0};
            }
        );
        hourChart
            .x(scaleBand())
            .y(scaleLinear().domain([0, 80]))
            .xUnits(dc.units.ordinal)
            .brushOn(false)
            .xAxisLabel(' ')
            .yAxisLabel('μg/m³')
            .dimension(stationDimension)
            .group(avgGroupPM25)
            .valueAccessor(function(p) { return p.value.count > 0 ? Math.round(p.value.total / p.value.count * 100) / 100 : 0; })
            .colorAccessor(function (p) {
                let tmp = p.value.count > 0 ? Math.round(p.value.total / p.value.count * 100) / 100 : 0;
                if (tmp < 10) {
                    return "class1";
                } else if (tmp >= 10 && tmp < 20) {
                    return "class2";
                } else if (tmp >= 20 && tmp < 25) {
                    return "class3";
                } else if (tmp >= 25 && tmp < 50) {
                    return "class4";
                } else if (tmp >50) {
                    return "class5";
                }
            })
            .colors(scaleOrdinal().domain(["class1", "class2", "class3", "class4", "class5"])
                .range(["#5ED5FF", "#92D14F", "#FFFF00", "#FC3903", "#990100"]));

        return hourChart
    }

    render() {
        return (
            <ChartTemplate chartFunction={this.hourChartFunc} resetFunction={true} title={this.props.i18n['thermiairAvgHour']}/>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n.messages
});

HourChart10 = connect(mapStateToProps)(injectIntl(HourChart10));

export default HourChart10;

